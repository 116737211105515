.checkoutPaymentButton {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 250px;
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1;
    background-color: #fff;
    .son {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        width: 50%;
        font-weight: bold;
        font-size: 40px;
    }
    .finalPriceBox {
        // background-color: #0d0544;
        // color: #fff;
        span {
            width: 50%;
        }
    }
    .btnBox {
        // background-color: #ffdc33;
        color: #000;
    }
    .left-title {
        display: flex;
        align-items: flex-start;
        justify-content: center;
        flex-direction: column;
        font-weight: bold;
        font-size: 40px;
        height: 100%;
        margin-left: 80px;
        flex: 4;

        .name {
            color: #888888;
            font-size: 25px;
            margin-bottom: 30px;
        }

        .price {
            color: #000;
            font-size: 50px;
            font-weight: bold;
            display: flex; 
            flex-wrap: wrap;
        }
    }

    .right {
        display: flex;
        flex: 6;
        align-items: center;
        justify-content: center;
        margin: 30px;

        .btnBox {
            background-color: #ffdc33;
            color: #000;
            border-radius: 100px;
            align-items: center;
            justify-content: center;
            display: flex;
            height: 175px;
            width: 100%;
        }
        .click {
            box-shadow: 0px 10px 25px #f3db0033;
        }
        .click:active {
            animation: click 0.2s ease;
        }
        @keyframes click {
            0% {
                transform: scale(1, 1);
            }
            50% {
                transform: scale(0.9, 0.9);
            }
            100% {
                transform: scale(1, 1);
            }
        }

        .text {
            font-size: 50px;
            margin-left: 30px;
        }
    }

    .disabled {
        background-color: #e8e8e8 !important;
    }

    .deliveryMinimumFeeAirBubbless {
        top: -108px !important;
        left: 40px !important;

        &::after {
            left: 65px !important;
            right: unset !important;
        }
    }

    .blackListBubbles {
        top: -108px !important;
        right: 40px !important;
        left: unset;

        &::after {
            right: 65px !important;
            left: unset !important;
        }
    }
    .airBubblesPositionBox {
        position: absolute;
        width: 50%;
        bottom: 200px;
        height: 1px;
        left: 48%;
        display: flex;
        justify-content: center;
    }
    .reviewTipBubbles {
        bottom: -10px !important;
        top: unset !important;
        right: unset !important;
        left: unset;
        background-color: #f4dee1;

        span {
            color: #c14d4d;
        }

        &::after {
            border-top-color: #f4dee1;
        }
    }

    .checkoutErrorTip {
        margin: 0 10px 10px;
        position: absolute;
        width: 98%;
        bottom: 250px;

        .MuiAlert-root {
            line-height: 50px;
        }

        .MuiAlert-standardError {
            color: #fd7b88;
            background-color: #f9e9e9;
        }
    }
}
