.featuredDishItem {
    background-color: #36353c;
    margin-right: 24px;
    height: 500px;
    width: 800px;
    flex-direction: column;
    border-radius: 80px;
    color: #fff;
    box-sizing: border-box;
    font-family: "Krub";
    position: relative;
    .dishImgbox {
        width: 100%;
        height: 100%;
        border-radius: 80px;
        .dishImg {
            min-width: 100%;
            max-width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 80px;
        }
    }
    .dishNameBox {
        position: absolute;
        bottom: 0;
        height: 120px;
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-left: 48px;
        box-sizing: border-box;
        font-size: 40px;
        user-select: none;
        .title {
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            height: auto;
            flex: 1;
            text-align: left;
        }
        .plusButton {
            width: 120px;
            background-color: #b07c4d;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            border-top-left-radius: 80px;
            border-bottom-right-radius: 80px;
            font-size: 65px;
            font-weight: bold;
        }
        .plusButton:active {
            background-color: #b07c4d80;
        }
        .add {
            position: absolute;
            right: 0;
            top: -50px;
            width: 120px;
            font-size: 40px;
            color: #fff;
            opacity: 0;
        }
        .addAnimation {
            animation: addAnimation 0.3s linear;
        }
    }
}
@keyframes addAnimation {
    0% {
        opacity: 0;
        top: 0px;
    }
    80% {
        opacity: 1;
        top: -30px;
    }
    100% {
        opacity: 0;
        top: -30px;
    }
}
