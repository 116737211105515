.sign-up-title {
    width: 100%;
    margin-top: 48px;
    height: 88px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 48px;
}

.sign-left-icon {
    margin-left: 48px;
    width: 88px;
    height: 88px;
}

.sign-up-title-text {
    color: "#000000";
    font-size: 55px;
    font-weight: bold;
}

.sign-up-phone-box {
    width: 100%;
    text-align: left;
    margin-top: 96px;
}

.sign-up-password-box {
    width: 100%;
    text-align: left;
    margin-top: 48px;
}

.sign-up-phone-text {
    font-size: 50px;
    color: #9892c0;
    margin-left: 10%;
}

.sing-up-phone-container {
    width: 80%;
    margin: 0 auto;
}

.sing-up-password-container {
    width: 80%;
    margin: 0 auto;
    margin-bottom: 48px;
}

.agreementSelectBox {
    width: 80%;
    margin: 0px auto 48px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.agreementTextAgree {
    color: #989eb6;
    font-size: 30px;
}

.agreementText {
    color: #5867b4;
    font-size: 30px;
}

.agreementSelect {
    width: 72px;
    height: 72px;
    background-color: #fff;
    display: inline-flex;
    justify-content: center;
    align-items: center;
}

.sing-up-phone {
    display: flex;
    width: 100%;
    flex-direction: row;
    background-color: #fff;
    border-radius: 88px;
    align-items: center;
    justify-content: center;
    height: 144px;
    margin-top: 48px;
    margin-bottom: 48px;
}

.sign-up-button {
    display: flex;
    padding: 0 24px;
    height: 96px;
    border-radius: 88px;
    background-color: #ffdc33;
    justify-items: center;
    align-items: center;
    font-size: 20px;
    white-space: nowrap;
}

.sign-up-button .MuiButton-root:hover {
    background-color: unset;
}

.sign-up-verification {
    display: flex;
    width: 100%;
    flex-direction: row;
    background-color: #fff;
    border-radius: 88px;
    align-items: center;
    justify-content: center;
    height: 144px;
    margin-top: 48px;
}

.signup-password-input {
    width: 40%;
    background: #fff;
    justify-content: center;
    align-items: center;
    border: none;
    height: 100%;
    /* color: #333; */
    font-size: 40px;
    border-radius: 88px;
    padding-left: 24px;
    font-family: "krub";
}

.sign-up-verification-text {
    margin-top: 48px;
    font-size: 50px;
    color: #9892c0;
    margin-left: 10%;
}

.sign-up-password-text {
    margin-top: 48px !important;
    font-size: 50px;
    color: #9892c0;
    margin-left: 10%;
}

.signup-button-div {
    display: flex;
    width: 90%;
    margin: auto;
    height: 144px;
    border-radius: 88px;
    background-color: #ffdc33;
    justify-items: center;
    align-items: center;
    margin-top: 24px;

    span {
        font-weight: bold;
        font-size: 40px;
    }
}

.signup-button-not-phone {
    display: flex;
    width: 90%;
    margin: auto;
    height: 144px;
    border-radius: 88px;
    background-color: #E0DCDC;
    justify-items: center;
    align-items: center;
    margin-top: 24px;

    span {
        font-weight: bold;
        font-size: 40px;
    }
}

.sign-up-password {
    display: flex;
    width: 100%;
    flex-direction: row;
    background-color: #fff;
    border-radius: 88px;
    align-items: center;
    justify-content: center;
    height: 144px;
}

.forget-password-button {
    width: 192px;
    height: 95px;
    border-radius: 88px;
    background-color: #ffdc33;
    justify-items: center;
    align-items: center;
    display: flex;
    font-family: "Krub Medium", "Krub Regular", "Krub";
    font-weight: 500;
    font-style: normal;
    font-size: 40px;
    color: #22223a;
    text-align: center;
}

.forget-password-text-div {
    width: 80%;
    height: 96px;
    margin-top: 62px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-left: 10%;
}

.forget-password-text {
    font-size: 45px;
    color: "#9791BF";
    color: #394474;
    /* width: 35%; */
}

.forget-password-get-phone {
    font-size: 40px;
    color: #394474;
    margin-right: 50px;
}

.forget-password-get-code {
    font-size: 40px;
    color: #bcbcbc;
}

.forget-password-text-first {
    margin-top: 48px;
    font-size: 50px;
    color: #9892c0;
    margin-left: 10%;
}

.forget-button-div {
    display: flex;
    background-color: #f8f8f8;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    margin-top: 358px;
}

.signUpPhoneInput .mainContainer {
    border-width: 0;
    background-color: #fff;

    .phone {
        &::-webkit-input-placeholder {
            color: #757575;
        }
    }

}

.signUpPhoneInput .disabledMainContainer {
    background-color: #b4b5b580;

    .phone {
        background-color: transparent;
    }
}

.sing-up-phone-container {
    .vCodeMismatched {
        text-align: left;
        color: #f00;
        font-size: 30px;
        padding: 23px;
    }

    .vCodeDisabledBox {
        background: #b4b5b580 !important;

        .signup-password-input {
            background: transparent !important;
        }

        .sign-up-button {
            background-color: #b4b5b5;
        }
    }
}