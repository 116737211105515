.OrderPackageMeal {
    background: #ffdc33;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 20;
    .main_container {
        position: fixed;
        left: 0;
        right: 0;
        top: 191px;
        bottom: 0;
        height: calc(100% - 191px);
        width: 100%;
        display: flex;
        flex-direction: column;
        box-sizing: border-box;
        border-radius: 50px 50px 0 0;
        overflow: hidden;
        padding: 40px 24px 0px 24px;
        transition: all 0.2s;
        background: #f8f8f8;
        display: block;
        .category {
            width: 100%;
            overflow: hidden;
            margin-bottom: 10px;
            ul {
                height: 100%;
                display: flex;
                flex-direction: row;
                float: left;
                white-space: nowrap;
                overflow-x: auto;
                overflow-y: hidden;
                li {
                    flex: none;
                    padding: 10px 48px;
                    height: 195px;
                    border-radius: 55px;
                    background-color: #f2f2f7;
                    margin-right: 24px;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    span {
                        font-size: 35px;
                        font-weight: bold;
                        color: #989eb6;
                    }
                    .maxNumDish {
                        margin: 15px 0;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        .handleRed {
                            color: #f65252;
                        }
                        span {
                            font-size: 35px;
                            font-weight: bold;
                            color: #989eb6;
                        }
                        .checkIcon {
                            background-color: #989eb6;
                            color: #ffffff;
                            height: 32px;
                            width: 32px;
                            border-radius: 16px;
                            text-align: center;
                            line-height: 32px;
                            .MuiSvgIcon-root {
                                width: 100%;
                                height: 100%;
                            }
                        }
                        .chosenCheckIcon {
                            background-color: #f6c41b;
                            color: #000000;
                            height: 32px;
                            width: 32px;
                            border-radius: 16px;
                            text-align: center;
                            line-height: 32px;
                            .MuiSvgIcon-root {
                                width: 100%;
                                height: 100%;
                            }
                        }
                    }
                    .chosenMaxNumDish {
                        margin: 15px 0;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        .handleRed {
                            color: #f65252;
                        }
                        span {
                            font-size: 35px;

                            color: #02821e;
                        }
                        .checkIcon {
                            background-color: #989eb6;
                            color: #ffffff;
                            height: 32px;
                            width: 32px;
                            border-radius: 16px;
                            text-align: center;
                            line-height: 32px;
                            .MuiSvgIcon-root {
                                width: 100%;
                                height: 100%;
                            }
                        }
                        .chosenCheckIcon {
                            background-color: #f6c41b;
                            color: #000000;
                            height: 32px;
                            width: 32px;
                            border-radius: 16px;
                            text-align: center;
                            line-height: 32px;
                            .MuiSvgIcon-root {
                                width: 100%;
                                height: 100%;
                            }
                        }
                    }

                    .addtionalFees {
                        padding: 10px 20px;
                        background-color: #000000;
                        color: #ffffff;
                        border-radius: 44px;
                        font-size: 35px;
                    }
                }
                .chosenLi {
                    background-color: #ffdc33;
                    span {
                        color: #000000;
                        font-size: 40px;
                    }
                    .maxNumDish {
                        .handleRed {
                            color: #f65252;
                        }
                        span {
                            font-size: 40px;
                            color: #000000;
                        }
                    }
                    .addtionalFees {
                        font-size: 40px;
                    }
                }
            }
        }
        .dishList {
            height: calc(100% - 195px);
            width: 100%;
            overflow-y: auto;
            .main_dishes_list_control {
                padding-top: 55px;
                text-align: left;
                .repeatTxt {
                    font-size: 40px;
                    color: #989eb6;
                    font-weight: bold;
                }
                ul {
                    flex: 1;
                    display: flex;
                    flex-direction: column;
                    padding-bottom: 200px;
                    margin-top: 55px;
                    li {
                        width: 100%;
                        margin-bottom: 24px;
                        .notData {
                            text-align: center;
                            font-size: 50px;
                            margin-top: 600px;
                            color: #bcbcbc;
                        }
                    }
                }
            }
        }
    }
}
