// 登入页面 - 输入手机号
.checkout-sign-in-phone {
    width: 820px;
    margin: 0 auto;
    flex: 1;
    padding: 112px 0 0 0;

    .tip-text {
        font-size: 35px;
        color: #bcbcbc;
        text-align: left;
        word-break: break-word;
    }

    .change-button {
        font-size: 35px;
        color: #394474;
        text-align: right;
        margin-top: 24px;
    }

    .signup-button-div {}

    .signup-button-not-phone {}

    .code-password-box {
        height: 250px;
    }

    .switch-container {
        width: 100%;
        font-size: 30px;
        color: #5E6899;
        margin: 64px 0;
    }

    .signin-password-input::-webkit-input-placeholder {
        font-size: 40px;
        color: #8f97c1 !important;
    }

    .verification-code-insert-in::-webkit-input-placeholder {
        font-size: 40px;
        color: #8f97c1 !important;
    }

    .agreePolicy {
        font-size: 30px !important;
        color: #989eb6;
        // margin: 36px 0;
    }

    .agreePolicy .clickPolicyText {
        color: #5867b4;
        padding-left: 10px;
    }

    .agreePolicyCheckBox {

        .clickPolicyText,
        .agreeText {
            font-size: 30px;
        }
    }

    .select-other-login-method-button {
        border-radius: 88px;
        margin-top: 50px;
        background-color: #ffde33;
    }

    .bottom-panel {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 148px;

        .line {
            border-top: 3px solid #bcbcbc;
            width: 10%;
        }

        .line-text {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 32px;
            font-size: 30px;
            color: #BCBCBC;
        }

        .login-button-div {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 36px;
            margin-top: 32px;
        }

        .switch-box {
            font-size: 35px;
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 16px;
            color: #5E6899;
            margin-top: 32px;

            .switch-text {}
        }

        .facebook {
            background: #485a96;
            cursor: pointer;
            border: 1px solid #bcbcbc;
            border-radius: 88px;
            display: flex;
            justify-content: center;
            align-items: center;

            .img {
                height: 88px;
                width: 88px;
            }

            .text {
                font-size: 40px;
                color: #fff;
                margin-left: 20px;
                width: 40%;
                white-space: nowrap;
            }
        }
    }
}

// 登入页面 - 输入验证码

.code-input-main {
    position: relative;
    display: flex;
    flex-direction: row;
    background-color: #fff;
    align-items: center;
    height: 144px;
    border-radius: 88px;
    overflow: hidden;
    margin: 30px 0 50px;

    .code-content {
        padding: 0 40px 0 60px;
        width: 100%;

        .pos-relative {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;

            .verification-code-insert-in {
                background: #fff;
                border: none;
                height: 100%;
                color: #394474;
                font-size: 4.63vw;
                padding-right: 90px;
                width: 100%;
            }
        }
        .code-timing {
            .send-code {
                // width: 32px;
                height: 94px;
                border-radius: 88px;
                background-color: #ffdc33;
                font-weight: 400;
                padding: 0 20px;
                text-transform: capitalize;
                word-break: break-all;
                white-space: nowrap;
            }
        }
    }

    .checkoutCheckIcon {
        right: 25px;
        color: #fff;
        width: 50px;
        height: 50px;
        padding: 1px;
        background-color: #2dcc56;
        border-radius: 50px;
        margin-right: 8px;
    }

    .checkoutClearIcon {
        right: 25px;
        color: #fff;
        width: 50px;
        height: 50px;
        padding: 1px;
        background-color: red;
        border-radius: 50px;
        margin-right: 8px;
    }

    .space {
        opacity: 0;
        right: 25px;
        width: 50px;
        height: 50px;
        padding: 1px;
    }
}

.google-box {
    background: #fff;
    border-radius: 88px;
    height: 144px;
    margin: 20px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #bcbcbc;

    .g_id_signin * {
        -webkit-box-sizing: unset;
    }

    .g_id_signin>div>div>div {
        border: none;
    }
}

// 登入页面 - 输入验证码 - 底部弹出框

.bottomPanel {
    background: #fff;
    padding: 64px;

    .facebook {
        background: #485a96;
        border-radius: 88px;
        height: 144px;
        // text-Transform: unset;
        margin: 20px 0;
        margin-top: 40px;
        display: flex;
        justify-content: center;
        align-items: center;

        .img {
            height: 88px;
            width: 88px;
        }

        .text {
            font-size: 40px;
            color: #fff;
            margin-left: 20px;
            width: 40%;
            white-space: nowrap;
        }
    }

    .google {
        background: #fff;
        border-radius: 88px;
        height: 144px;
        // text-Transform: unset;
        margin: 20px 0;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid #bcbcbc;

        .img {
            height: 72px;
            width: 72px;
        }

        .text {
            font-size: 40px;
            color: black;
            margin-left: 20px;
            width: 40%;
        }
    }

    .bottomPanelButton {
        border-radius: 88px;
        height: 144px;
        text-transform: unset;
        margin: 20px 0;
        background-color: #ffde33;
    }

    .bottomPanelButton-active {
        border-radius: 88px;
        height: 144px;
        text-transform: unset;
        margin: 20px 0;
        background-color: #e0dcdc;
    }

    .cancelPanelButton {
        background: #0d0544;
        color: #fff;
    }
}

.complete-info-component {
    // width: 100%;
    flex: 1;
    margin: 0 42px 0 42px;
    overflow-y: auto;

    .info-top-bar {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;

        .complete-title {
            width: 100%;
            text-align: center;
            font-size: 50px;
            font-weight: 600;
        }
    }

    .tip-text {
        font-size: 35px;
        color: #bcbcbc;
        text-align: left;
        word-break: break-word;
    }

    .switch-container {
        width: 100%;
        font-size: 30px;
        color: #5E6899;
        margin: 64px 0;
    }

    .customer-com {
        margin-top: 72px;

        .customer-name {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;

            .icon {
                width: 45px;
                height: 45px;
                color: #000;
                padding: 16px;
                background-color: #ffdc33;
                border-radius: 64px;

                svg {
                    width: 100%;
                    height: 100%;
                }
            }

            .customer-name-title {
                font-size: 40px;
                font-weight: 600;
                margin-left: 48px;
            }
        }

        .name {
            margin-top: 48px;

            .interval {
                margin: 48px 0;
            }

            .tipsText {
                text-align: left;
                padding: 0 30px;
                margin-top: 30px;
                color: #fd7b88;
                font-weight: bold;
                font-size: 40px;
            }

            .complete-google-map {
                height: 144px;
                width: 100%;
                border-radius: 88px;

                .SearchBox {
                    width: 100%;
                    margin: 40px auto;
                }

                .SearchBoxinput {
                    font-family: "kurd";
                    box-sizing: border-box;
                    border: 1px solid transparent;
                    height: 144px;
                    background-color: #fff;
                    border: none;
                    border-radius: 88px;
                    padding: 0 60px;
                    outline: none;
                    font-size: 40px;
                    color: #5867b4;
                    width: 100%;
                }
            }
        }
    }

    .password-box {
        width: 100%;
        text-align: left;

        .password-title {
            margin-top: 48px;
            display: flex;
            flex-direction: row;
            align-items: center;

            .password-text {
                font-weight: 600;
                margin-left: 48px;
                font-size: 40px;
            }

            .icon {
                width: 45px;
                height: 45px;
                color: #000;
                padding: 16px;
                background-color: #ffdc33;
                border-radius: 64px;

                svg {
                    width: 100%;
                    height: 100%;
                }
            }
        }

        .password-container {
            width: 100%;
            margin: 0 auto;

            .password {
                display: flex;
                width: 100%;
                flex-direction: row;
                background-color: #fff;
                border-radius: 88px;
                align-items: center;
                justify-content: center;
                height: 144px;
                margin-top: 48px;
            }
        }
    }

    .checkbox-repi-com {
        display: flex;
        flex-direction: row;
        margin-top: 48px;
        align-items: center;

        .checkbox {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 48px;
            height: 48px;
            border: 0.4px solid #989eb6;
            border-radius: 18px;
            background-color: #fff;

            .img {
                width: 36px;
                height: 36px;
            }
        }

        .text {
            font-size: 40px;
            font-weight: 600;
            margin-left: 48px;
        }
    }

    .replce-repi-area {
        margin-top: 48px;

        .interval {
            margin-top: 48px;
        }
    }

    .complace-btn {
        border-radius: 88px;
        height: 144px;
        text-transform: unset;
        margin: 144px 50px;
        background-color: #e0dcdc;
    }

    .complace-btn-active {
        border-radius: 88px;
        height: 144px;
        text-transform: unset;
        margin: 144px 50px;
        background-color: #ffde33;
    }

    .customer-phone-bind {
        .code-input-main {
            width: auto;
        }

        .code-content {
            position: relative;
            display: flex;
            align-items: center;
            width: 100%;
            justify-content: space-between;
            padding: 0 40px 0 60px;

            .code-timing {
                .send-code {
                    width: 312px;
                    height: 94px;
                    border-radius: 88px;
                    background-color: #ffdc33;
                    font-weight: 400;
                    padding: 0 20px;
                    text-transform: capitalize;
                    word-break: break-all;
                    white-space: nowrap;
                }
            }
        }
    }
}

// 同意隐私协议 底部弹出框

.agree-bottom-panel-content {
    background-color: #fff;
    padding: 18px 64px 128px;
    font-size: 35px;
    text-align: center;
    color: #394374;
    font-weight: bold;
    border-top-left-radius: 50px;
    border-top-right-radius: 50px;

    .close-btn {
        position: absolute;
        // top: 0;
        right: 0;
    }

    .bottom-panel-title {
        border-bottom: 1px solid #394374;
        height: 80px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .bottom-panel-content-text {
        margin-top: 64px;
        color: #5E6899;
    }

    .agree-button-div {
        background-color: #ffdc33;
        border-radius: 88px;
        width: 80%;
        margin: auto;
        margin-top: 48px;

        span {
            font-weight: bold;
            font-size: 40px;
        }
    }
}

.v-code-dialog {
    .v-code-dialog-wrapper {
        padding: 48px 48px 12px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    .v-code-dialog-content {
        font-size: 40px;
        padding-bottom: 96px;
    }

    .v-code-dialog-footer {
        width: 100%;
        display: flex;

        .btn {
            flex: 1;
        }

        .confirm {
            color: #5E6899
        }
    }
}