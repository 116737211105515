.App {
  text-align: center;
}

.App .MuiButton-root {
  text-transform: none;
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.test {
  width: 750px;
}

.globalLoading {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  /* background: rgb(241, 244, 248, .5); */
  background: rgb(0, 0, 0, 0.5);
}

.laytoutCenter {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.laytoutCenter .ignore_iframe {
  width: 414px;
  height: 736px;
}

/* @media screen and (max-height:736px) {
  .laytoutCenter{
    position: relative;
  }
  .laytoutCenter .ignore_iframe{
    position: fixed;
    height: 100%;
    top: 0;
    bottom: 0;
  }
} */

.App ::-webkit-scrollbar {
  display: none;
}
.grecaptcha-badge {
  visibility: hidden;
}

.downloadBtn .MuiButton-label {
  color: #fff;
  text-transform: unset;
  background-color: #fff5;
  border-radius: 10px;
}

/* 虚拟列表 */
.ReactVirtualized__List {
  outline: 0;
}