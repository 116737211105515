.blackGoldThemeAddress {
    .delivery-address-main {
        display: flex;
        flex: 1;
        width: 100%;
        flex-direction: column;
        background-color: #282733;
        .backgroundTransparent {
            background-color: #282733 !important;
            div {
                color: #fff;
            }
            .bar-left-icon {
                height: 48px;
                width: 48px;
            }
            .title {
                color: #fff;
                font-size: 40px;
            }
            .info {
                color: #b07c4d;
            }
            .MuiButton-root {
                color: #fff;
            }
        }
    }

    .delivery-address-main .createAddress {
        height: auto;
    }

    .delivery-font-style-BC {
        font-family: "Krub";
        font-weight: 400;
        font-style: normal;
        font-size: 40px;
        color: #bcbcbc;
    }

    .delivery-font-style-6C {
        font-family: "Krub";
        font-weight: 400;
        font-style: normal;
        font-size: 40px;
        color: #6c6c6c;
    }

    .delivery-address-content {
        height: calc(100% - 196px - 50px);
        margin: 0 auto 50px;
        .delivery-address-list {
            height: calc(100% - 150px);
            overflow: auto;
            border-radius: 40px;
            background: #282733;
        }
        .phone-main {
            .phone-insert-in {
                width: 600px;
                padding: 20px;
                background: #fff;
                justify-content: center;
                align-items: center;
                border: none;
                height: 100%;
                color: #394474;
                font-size: 50px;
            }
        }
        .input-main {
            position: relative;
            display: flex;
            flex-direction: row;
            background-color: #fff;
            align-items: center;
            justify-content: center;
            height: 144px;
            border-width: 1px;
            border-style: solid;
            border-color: rgba(207, 207, 207, 1);
            border-radius: 88px;
            overflow: hidden;
        }
        .confirm {
            width: 304px;
            height: 104px;
            border-radius: 30px;
            background-color: #b07c4d !important;
            font-size: 30px;
            color: #fff;
            font-family: "Arial Normal", "Arial";
            font-weight: 400;
        }
        .notChoose {
            width: 304px;
            height: 104px;
            border-radius: 30px;
            background-color: #bcbcbc !important;
            font-size: 30px;
            color: #fff;
            font-family: "Arial Normal", "Arial";
            font-weight: 400;
        }
        .main-body {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            height: 100%;
            padding-bottom: 50px;
            align-items: center;
            .address-tit {
                font-weight: 600;
                font-size: 40px;
                color: #caa376;
            }
            .address-msg {
                font-weight: bold;
                font-size: 35px;
                color: #b5b4b4;
                margin-top: 48px;
            }
            .address-main-bg {
                width: 936px;
                // height: 1368px;
                background-color: #282733;
                padding: 0px 40px;
            }
            .address-main {
                display: flex;
                align-items: center;
                justify-content: space-between;
                flex-direction: column;
                margin-top: 24px;
                word-break: break-word;
            }
            .address-card {
                display: flex;
                flex-direction: row;
                // justify-content: space-evenly;
                width: 100%;
                // height: 308px;
                border-radius: 22px;
                margin-bottom: 24px;
                .left {
                    border-radius: 22px 0 0 22px;
                    flex: 1;
                    background-color: #353742;
                    color: #fff;
                    padding: 32px 48px;
                    .country {
                        margin-bottom: 64px;
                        div {
                            text-align: left;
                            font-size: 40px;
                            font-family: "Krub";
                            font-weight: bold;
                            font-style: italic;
                        }
                    }
                    .name {
                        margin-bottom: 32px;
                        text-align: left;
                        font-size: 35px;
                    }
                    .phone {
                        font-size: 35px;
                        text-align: left;
                    }
                    .within {
                        text-align: left;
                        font-size: 48px;
                        color: red;
                        margin-top: 20px;
                    }
                }
                .right {
                    border-radius: 0 22px 22px 0;
                    color: #fff;
                    width: 25%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    background: #424451;
                }
            }
        }
    }

    // 修改地址弹窗
    .deliveryAddressModal {
        .account-tit {
            color: #9791bf;
            font-weight: 600;
            font-style: normal;
            font-size: 40px;
        }
        .dialogName {
            position: relative;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            margin-bottom: 6.667vw;
            .fontStyle {
                font-size: 55px;
                width: 100%;
                text-align: center;
            }
            .close {
                position: absolute;
                top: 0;
                right: 0;
            }
            .third-party-main {
                display: flex;
                flex-direction: column;
                align-items: center;
                .description {
                    font-family: "Arial Normal", "Arial";
                    font-size: 40px;
                    color: #bcbcbc;
                    text-align: left;
                    font-weight: 400;
                }
                .tit {
                    font-size: 55px;
                }
                .tit-info {
                    font-size: 40px;
                    color: #bcbcbc;
                    text-align: left;
                    font-weight: 400;
                    text-align: center;
                    margin: 10px 0;
                }
            }
            .thirdPartyIcon img {
                width: 100%;
            }
            .mark-remark {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 129px;
                height: 59px;
                background-color: #6aaaff;
                color: #fff;
                font-family: "Krub Medium", "Krub Regular", "Krub";
                font-weight: 500;
                font-style: normal;
                font-size: 40px;
                border-radius: 112px;
                margin-right: 32px;
                padding: 0 50px;
            }
        }
    }

    .deliveryAddressModal .MuiDialog-paperWidthSm {
        width: 952px;
        height: 1800px;
        border-radius: 42px;
        // 确认按钮
        .confirm {
            width: 100%;
            height: 104px;
            border-radius: 88px;
            background-color: #ffdc33;
            font-size: 50px;
            font-family: "Arial Normal", "Arial";
            font-weight: 400;
            // margin-top: 50px;
            text-transform: capitalize;
        }
    }

    .editInput {
        height: 144px;
        background-color: rgba(88, 103, 180, 0);
        box-sizing: border-box;
        border-width: 3px;
        border-style: solid;
        border-color: rgba(207, 207, 207, 1);
        border-radius: 88px;
        font-size: 40px !important;
        color: #9791bf !important;
        padding: 0 40px;
    }
}
