.orderPage {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    // overflow: hidden;
    .tool-icon {
        width: 72px;
        height: 72px;
    }
    .tune-tool {
        position: relative;
    }
    .coupons-com-second {
        background-color: #282733;
        height: 200px;
        display: flex;
        justify-content: center;
        .coupons {
            display: flex;
            flex-direction: row;
            background-color: #ff813a;
            border-radius: 36px;
            height: 86px;
            overflow-x: auto;
            width: 896px;
            padding: 0 24px;
            .coupons-container {
                display: flex;
                flex-direction: row;
                width: 100%;
                overflow-x: auto;
                .coupon-area {
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    align-items: center;
                    white-space: nowrap;
                    padding: 0 12px;
                    .icon {
                        display: block;
                        color: #fff;
                        height: 48px;
                        width: 48px;
                    }
                    .text {
                        display: block;
                        color: #fff;
                        font-size: 36px;
                    }
                    .text-point {
                        background-color: #fff;
                        width: 12px;
                        height: 12px;
                        border-radius: 88px;
                        margin: 0px 24px;
                    }
                }
            }

        }
    }

    .coupons-com {
        background-color: #fcdd33;
        height: 200px;
        display: flex;
        justify-content: center;
        .coupons {
            display: flex;
            flex-direction: row;
            background-color: #ff813a;
            border-radius: 36px;
            height: 86px;
            width: 896px;
            padding: 0 24px;
            .coupons-container {
                display: flex;
                flex-direction: row;
                width: 100%;
                overflow-x: auto;
                .coupon-area {
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    align-items: center;
                    white-space: nowrap;
                    &:not(:last-child) {
                        padding-right: 48px;
                    }
                    .icon {
                        display: block;
                        color: #fff;
                        height: 48px;
                        width: 48px;
                    }
                    .text {
                        display: block;
                        color: #fff;
                        font-size: 36px;
                    }
                    .text-point {
                        background-color: #fff;
                        width: 12px;
                        height: 12px;
                        border-radius: 88px;
                        margin: 0px 24px;
                    }
                }
            }

        }
    }

    .tune-tool .cue-bubble {
        position: absolute;
        top: -25px;
        right: -25px;
        width: 50px;
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #6aaaff;
        color: #fff;
        border-radius: 50%;
        font-size: 30px;
    }
    .darkTopbar {
        background-color: #282733;
        div {
            color: #fff;
        }
        .title {
            color: #fff;
        }
        .info {
            color: #b07c4d;
        }
    }
    .showCouponHeight {
        top: calc(197px + 100px) !important;
    }
    .order_main_container {
        position: fixed;
        left: 0;
        right: 0;
        top: 177px;
        bottom: 0;
        display: flex;
        flex-direction: column;
        box-sizing: border-box;
        border-top-left-radius: 50px;
        border-top-right-radius: 50px;
        overflow: hidden;
        padding-bottom: 300px;
        transition: all 0.2s;
        background: #ffffff;
        display: block;
        .main_container_slide {
            width: 100%;
            overflow: hidden;
        }
        .all_dish_contrainer {
            padding-bottom: 300px;
        }
        .main_container_dish_list {
            // flex: 1;
            // -webkit-overflow-scrolling: touch;
            background: #f8f8f8;
            flex: 1;
            height: 100%;
            overflow: hidden;
            position: relative;
            display: flex;
            justify-content: center;
        }
        .blackGoldTheme_main_container_dish_list {
            background-color: #282733;
        }
        .main_container_flex_footer {
            position: fixed;
            left: 40px;
            bottom: 50px;
            z-index: 6;
            width: calc(100vw - 80px);
        }
    }
    .blackGoldTheme_order_main_container {
        background: #282733;
        border-radius: 0;
    }

    .dishes_filter_continer {
        position: fixed;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        background: rgba(0, 0, 0, 0.3);
        z-index: 10;
    }

    .order_search_container {
        position: fixed;
        top: 171px;
        bottom: 0;
        right: 0;
        left: 0;
        box-sizing: border-box;
        border-radius: 22px;
        overflow: hidden;
        background: #ffffff;
        z-index: 20;
    }

    .order_shop_cat_container {
        position: absolute;
        // display: flex;
        // flex-direction: column-reverse;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 16;
        background: rgba(0, 0, 0, 0.3);
    }
    .main_container_flex_footer {
        position: fixed;
        left: 40px;
        bottom: 50px;
        z-index: 18;
        width: calc(100vw - 80px);
    }
    .main_container_flex_second_footer {
        position: fixed;
        left: 0;
        bottom: 0;
        right: 0;
        z-index: 18;
        // width: calc(100vw - 80px);
    }
}
.orderFilterDialog {
    font-family: Medium;
    .MuiDialog-paperWidthSm {
        border-radius: 70px;
    }
    .filter {
        display: flex;
        flex-direction: column;
        .filter-content {
            display: flex;
            flex-wrap: wrap;
            justify-content: right;
            .filter-labeld {
                height: 94px;
                font-size: 40px;
                color: #989eb6;
                border: 1px solid #ccc;
                border-radius: 50px;
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 0 40px;
                margin-right: 24px;
                margin-bottom: 48px;
            }
        }
    }
    .clear-filter-box {
        font-size: 40px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 30px;
        font-family: Medium;
        font-weight: bold;
        // width: 100%;
        // text-align: right;
        .clear-filter {
            color: #6aaaff;
        }
        .title {
            color: #989eb6;
        }
    }
    .second-clear-filter-box {
        padding-top: 50px;
        border-top: 1px solid #cccccc42;
    }
    .subtitle {
        font-size: 40px;
        padding-bottom: 20px;
        color: rgb(192, 192, 192);
    }
    .dialogName {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 72px;
        .close {
            position: absolute;
            top: 0;
            right: 0;
        }
    }
    .dialogContent {
        display: flex;
        flex-direction: column;
        // height: 500px;
    }
    input {
        width: 100%;
        min-height: 120px;
        color: #9791bf;
        border-radius: 50px !important;
        padding: 25px 35px;
        word-break: break-word;
        border-radius: 22px;
        box-sizing: border-box;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        background-color: #f8f8f8;
    }
    .insertCodebtn {
        width: 100%;
        text-transform: capitalize;
    }
    .dialogName .fontStyle {
        font-size: 55px;
        width: 100%;
    }
}
