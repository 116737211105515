.upgradingPage {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    p {
        color: #000;
        font-size: 68px;
    }
}
