.blackGoldCouponItem {
    * {
        user-select: none;
    }
    .isCheckCoupon {
        width: 900px;
        margin: 0 auto 30px;
        padding: 0px;
        position: relative;
        .couponItem {
            width: 100%;
            margin-bottom: 30px;
            transform: translate3d(72px, 0px, 0px) !important;
            transition: transform 0.1s;
        }
    }

    .couponItem {
        width: 930px;
        margin: 0 auto 30px;
        background-color: #b9aea4;
        transition: transform 0.1s;
        font-family: "Krub";
        .ruleText {
            width: 100%;
            height: 100%;
            display: flex;
            padding: 32px 48px;
            box-sizing: border-box;
            height: 280px;
            position: relative;
            overflow: hidden;
            .circle {
                position: absolute;
                width: 60px;
                height: 60px;
                background-color: #282733;
                border-radius: 30px;
                z-index: 1;
            }
            .backgroundValue {
                position: absolute;
                right: 100px;
                bottom: 0;
                // width: 100%;
                // height: 100%;
                font-style: italic;
                color: #fff;
                font-size: 250px;
                font-weight: bold;
                opacity: 0.2;
            }
            .left {
                top: calc(50% - 25px);
                left: -25px;
            }
            .right {
                top: calc(50% - 25px);
                right: -25px;
            }
            .discountValueBox {
                width: 40%;
                display: flex;
                font-weight: bold;
                color: #79340e;
                .discountValue {
                    font-size: 190px;
                    width: 65%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
                .unit {
                    display: flex;
                    flex-direction: column;
                    width: 35%;
                    span {
                        height: 50%;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        font-size: 45px;
                    }
                    span:first-child {
                        font-size: 75px;
                    }
                }
            }
            .nameBox {
                width: 60%;
                margin-left: 32px;
                position: relative;
                .name {
                    height: 50%;
                    display: flex;
                    align-items: flex-end;
                    justify-content: flex-start;
                    span {
                        font-size: 50px;
                        color: #44454d;
                        text-align: left;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        display: -webkit-box;
                        -webkit-box-orient: vertical;
                        -webkit-line-clamp: 2;
                        position: absolute;
                        z-index: 1;
                        word-break: break-word;
                    }
                }
                .date {
                    height: 50%;
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    font-size: 40px;
                    color: #77614d;
                    position: absolute;
                    z-index: 1;
                    top: 50%;
                }
            }
        }
        .restaurantCouponItem {
            // height: 300px;
        }
        .getBox {
            height: 120px;
            background-color: #5d4c3c;
            width: 100%;
            overflow: auto;
            font-family: "kurb Medium";
            font-size: 45px;
            color: #c8a275;
            display: flex;
            align-items: center;
            justify-content: center;
            .getBoxItem {
                width: 50%;
                display: flex;
                align-items: center;
                justify-content: center;
            }
            .line {
                width: 1px;
                height: 50%;
                background-color: #513f2e;
            }
            // .getBoxItem:first-child {
            //     border-right: 1px solid #382e24;
            // }
        }
    }

    .checkedItem {
        background-color: #b07c4d;
    }
    .ruleText:active {
        background-color: #835c3a;
    }
    .disabledItem {
        opacity: 0.5;
    }
    .disabledItem:active {
        background-color: #b07b4d85;
    }
    .doneicon {
        width: 48px;
        height: 48px;
        border-radius: 48px;
        font-weight: bold;
        color: #ffdc33;
        background: #3d3c69;
    }
    .placeholderBox {
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 48px;
        height: calc(176px - 48px);
        padding: 24px 0;
        margin-right: 24px;
        border-radius: 48px;
        margin-bottom: 30px;
    }
    .description {
        width: 900px;
        margin: 0 auto 50px;
        font-size: 30px;
        color: #caa476;
        text-align: left;
        transition: transform 0.1s;
        font-family: "kurb Medium";
        .exemptionRulesText {
            display: block;
            .title {
                font-weight: bold;
            }
        }
    }
    .checkedDescription {
        color: #caa476;
        transform: translate3d(72px, 0px, 0px);
        transition: transform 0.1s;
    }
}
