.signin_select {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin-top: 48px;
    margin-right: 72px;
}
.signInLogoBox {
    display: flex;
    align-items: center;
    justify-content: center;
}
.signInLogoBox .signInLogo {
    width: 224px;
    height: 224px;
}
.sigin-title {
    margin-top: 48px;
    font-size: 55px;
}

.signin-label {
    font-size: 18px;
}
.signin-visibility {
    color: #9791bf;
    width: 48px;
    height: 48px;
}

.signin-password-input {
    width: 60%;
    background: #fff;
    justify-content: center;
    align-items: center;
    border: none;
    height: 100%;
    color: #9791bf;
    font-size: 50px;
    margin-left: 24px;
}
.signin-password-input::-webkit-input-placeholder {
    color: #fff !important;
}
.isNotText::-webkit-input-placeholder {
    color: red !important;
}
.signin-password {
    display: flex;
    width: 100%;
    flex-direction: row;
    background-color: #fff;
    border-radius: 88px;
    align-items: center;
    justify-content: center;
    height: 144px;
    margin-top: 48px;
}
.signin-verification {
    display: flex;
    width: 100%;
    flex-direction: row;
    background-color: #fff;
    border-radius: 88px;
    align-items: center;
    justify-content: center;
    height: 144px;
    margin-top: 48px;
}
.signin-phone-container {
    width: 80%;
    margin: 0 auto;
}
.signin-phone {
    display: flex;
    width: 100%;
    flex-direction: row;
    background-color: #fff;
    border-radius: 88px;
    align-items: center;
    justify-content: center;
    height: 144px;
    margin-top: 100px;
}
.signin-operating {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
    width: 80%;
    margin-top: 48px;
    height: 72px;
}
.signin-operating-text {
    text-align: right;
    color: #989eb6;
    font-size: 50px;
    /* word-break: break-word; */
}

.signin-select {
    width: 72px;
    height: 72px;
    background-color: #fff;
    display: inline-flex;
    justify-content: center;
    align-items: center;
}
.disabledSignInBtn {
    background-color: #b4b5b5;
}
.signin-button {
    display: flex;
    width: 100%;
    height: 144px;
    border-radius: 88px;
    background-color: #ffdc33;
    color: #22223a;
    font-weight: bold;
    margin-top: 100px;
}
.signin-button-div {
    display: flex;
    width: 100%;
    height: 144px;
    border-radius: 88px;
    background-color: #ffdc33;
    justify-items: center;
    align-items: center;
}
.signin-button-div .MuiButton-label {
    font-size: 50px;
}
.agreePolicy {
    font-size: 30px;
    color: #989eb6;
}
.agreePolicy .clickPolicyText {
    color: #5867b4;
    padding-left: 10px;
}
.sign-up {
    color: #ffdc33;
    font-size: 50px;
    margin-top: 40px;
}
.other-signIn {
    display: flex;
    margin-top: 48px;
    width: 100%;
    height: 168px;
    margin-bottom: 50px;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.other-label {
    font-size: 50px;
    color: #ffdc33;
    margin-left: 48px;
}
.other-label:first-child {
    margin-left: 0px;
}
.other-signIn .base-icon {
    font-size: 80px;
    box-shadow: none;
    border: 1px solid #474747;
    background-color: transparent;
}

.country-select-menu {
    font-size: 50px;
    text-align: left;
}
.signinPage .change-password-content .main-body .item-style {
    width: 100%;
}
.signinPage .confirm {
    width: 792px;
    height: 144px;
    border-radius: 88px;
    background-color: #ffdc33 !important;
    font-size: 50px;
    font-family: "Arial Normal", "Arial";
    font-weight: 400;
    margin-bottom: 48px;
}
.password-tip-text{
    font-size: 35px;
    color: #aaa;
    margin-top: 16px;
}