.checkoutTotalPrice {
    margin: 48px 0;
    .totalPriceItem {
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 40px;
        margin: 48px 0;
        .content {
            display: flex;
            align-items: center;
            justify-content: center;
            .couponIcon {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 48px;
                height: 48px;
                color: #5867b4;
                svg {
                    width: 100%;
                    height: 100%;
                }
            }
            .couponIconBox {
                margin: 0 24px;
            }
        }
        .originalTotalPrice {
            font-weight: bold;
        }
    }
    .delLine {
        text-decoration: line-through; //删除线
    }
}

.total-price-style {
    background-color: #fff;
    opacity: 0.5;
}
