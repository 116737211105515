.snackbar-com {
    padding: 0px !important;
    margin: 0px !important;
    position: absolute !important;
    display: flex !important;
    align-items: flex-start !important;
    justify-content: flex-start !important;
    height: 10vh !important;
    .MuiSnackbar-root {
        padding: 0px !important;
        margin: 0px !important;
        position: absolute !important;
        top: 0px;
        min-height: 98px !important;
    }
    .MuiSnackbarContent-root {
        margin: 0px !important;
        border-top-left-radius: 0px;
        border-top-right-radius: 0px;
    }
    .MuiSnackbarContent-message {
        padding: 12px 0 4px 0 !important;
        // // height: 60px !important;
        // padding: 0px !important;
        // top: 0px !important;
    }
}
