.myOrderPage {
    background-color: #f8f8f8;
    // height: 100vh;
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    .delLine {
        text-decoration: line-through;
        margin-left: 0.08rem;
    }
    .myOrderPageContainer {
        margin: 0 72px;
        flex: 1;
        // height: calc(100% - 54px);
        // height: inherit;
        position: relative;
        // 订单状态切换
        .switchStatus {
            display: grid;
            grid-template-columns: repeat(3, auto);
            grid-column-gap: 72px;
            margin: 48px 0;
            justify-items: center;
            align-items: center;
            button {
                padding: 0 24px;
                height: 72px;
                min-width: 264px;
                border-radius: 88px;
                text-transform: none;
            }
            button.active {
                height: 96px;
                background-color: #ffdc33;
            }
        }
        .wrappper {
            position: absolute;
            left: 0;
            right: 0;
            top: 200px;
            bottom: 0;
        }
    }
}

// 每个订单卡片
.orderCard {
    display: grid;
    padding: 48px;
    grid-template-rows: repeat(2, auto);
    grid-row-gap: 24px;
    border-radius: 22px;
    box-shadow: 0px 10px 20px #f2f2f2;
    background-color: #ffffff;
    &.processing {
        border: 1px solid #ff7f7f;
    }
    .title {
        justify-self: start;
        color: #9791bf;
        font-size: 40px;
    }
    .info {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-column-gap: 24px;
        align-items: center;
        span {
            justify-self: start;
            &.price {
                color: #394474;
                font-size: 50px;
                font-weight: bold;
            }
            &.date {
                color: #bcbcbc;
                font-size: 40px;
                font-weight: bold;
            }
        }
    }
}
// 订单状态按钮
button.orderStatusBtn {
    justify-self: center;
    font-size: 30px;
    text-align: left;
    text-transform: none;
    border-radius: 50px;
    color: #5867b4;
    opacity: 0.5;
    border-color: #5867b4;
    // white-space: nowrap;
    line-height: inherit;
    &.active {
        opacity: 1;
        &.normalActiveBackgroundColor {
            background-color: rgba(80, 103, 180, 0.07);
        }
    }
}

// 分类面板
.categoryWrapper {
    min-height: 80vh;
    .categoryPanel {
        margin: 48px 0;
        & > * {
            margin-bottom: 48px;
        }
        & > .title {
            padding-left: 48px;
            text-align: left;
            color: #bcbcbc;
            font-size: 40px;
        }

        .MuiButton-label {
            white-space: pre-wrap;
        }
    }
}

// 订单详情
.myOrderDetailPage {
    background-color: #f8f8f8;
    // height: 100vh;
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    .delLine {
        text-decoration: line-through;
    }
    .myOrderDetailPageContainer {
        // flex:1; 171px是头部导航高度
        // height: calc(100% - 171px - 120px);
        // height: calc(100% - 60px);
        flex: 1;
        // height: inherit;
        margin: 0 72px 0 72px;
        padding-top: 48px;
        padding-bottom: 72px;
        display: flex;
        flex-direction: column;
        position: relative;
        .orderDetail {
            position: absolute;
            top: 48px;
            left: 0px;
            right: 0px;
            bottom: 400px;
            // 订单详情面板
            .orderDetailPanel {
                .showDetail {
                    background-color: #fff;
                    border-radius: 22px;
                    padding: 72px 48px 48px 48px;
                    .detailHead {
                        padding-bottom: 48px;
                        border-bottom: 1px solid #f2f2f2;
                        margin-bottom: 48px;
                        .title {
                            font-size: 50px;
                            font-weight: bold;
                            margin-bottom: 48px;
                            span {
                                color: #6aaaff;
                                font-size: 40px;
                            }
                        }
                    }
                    h2 {
                        text-align: center;
                        color: #394474;
                        margin: 48px 0;
                        font-size: 40px;
                    }
                    .contact {
                        color: #bcbcbc;
                        .title {
                            color: #626262;
                            font-size: 40px;
                            font-weight: bold;
                            margin-bottom: 48px;
                        }
                        text-align: left;
                        .contactDetail {
                            font-size: 40px;
                            border: 1px solid #f2f2f2;
                            padding: 48px;
                            border-radius: 40px;
                            box-shadow: 0 10px 25px #f2f2f2;
                            .address {
                                color: #6c6c6c;
                            }
                            margin-bottom: 48px;
                        }
                    }
                    .requirementsText {
                        color: #9791bf;
                        text-align: left;
                        margin-bottom: 48px;
                        font-size: 40px;
                        word-break: break-word;
                    }
                }
                .summary {
                    margin: 96px 0;
                }
            }
        }
        .footer {
            position: absolute;
            bottom: 72px;
            left: 0px;
            right: 0px;
            display: flex;
            flex-direction: column;
            align-content: space-between;
            font-size: 40px;
            & > * {
                display: flex;
                justify-content: space-between;
                align-items: center;
            }
            .amount {
                margin: 72px 0 48px;
                font-weight: bold;
                span:first-child {
                    font-size: 40px;
                }
                span:last-child {
                    font-size: 50px;
                }
            }
            .cancelOrder {
                color: #5867b4;
                text-decoration: underline;
            }
            .footerBtn {
                margin: 72px 0 0 0;
                .btnDetail {
                    min-width: 648px;
                    display: flex;
                    button {
                        border-radius: 88px;
                        text-transform: none;
                        flex: 1;
                        margin-right: 40px;
                        &:last-child {
                            margin-right: 0;
                        }
                    }
                }
                .call {
                    width: 114px;
                    height: 114px;
                    background-color: #fff;
                }
            }
        }
        .orderDishDetail {
            margin: 48px 0;
            background-color: #fff;
            border-radius: 22px;
            padding: 72px 48px 48px 48px;
        }
    }
}
.scrollOrderDialog {
    .dialogOrderDetail {
        font-size: 40;
        .dish,
        .summary {
            border-bottom: 1px solid #f5f5f5;
        }
        .dish {
            margin: 72px 0;
            .serMealArea-2 {
                padding-left: 40px;
                color: #989eb6;
            }
        }
        .summary {
            margin-bottom: 48px;
            .left {
                color: #5867b4;
            }
            .right {
                color: #9791bf;
            }
        }
    }
    .actionFooter {
        flex: 1;
        color: #000;
        .right {
            font-weight: bold;
            color: #000;
        }
    }
}

// 取消订单
.dialogCancelOrder {
    .otherReasons,
    .chooseReasons {
        .title {
            font-size: 40px;
            color: #626262;
            margin-bottom: 48px;
        }
    }
    .chooseReasons {
        margin-top: 96px;
        .choose {
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            button {
                margin-bottom: 48px;
            }
        }
    }
    .otherReasons {
        margin-top: 72px;
        margin-bottom: 72px;
        .input {
            background-color: #f8f8f8;
            border-radius: 70px;
            padding-top: 48px;
            padding-left: 48px;
            textarea {
                color: #323161;
            }
        }
    }
}

// 订单请求
.scrollOrderDialog {
    .requestFooter {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-column-gap: 24px;
        .call {
            width: 114px;
            height: 114px;
            background-color: #fff;
        }
        .dialogButton {
            font-weight: bold;
            font-size: 40px;
            width: 288px !important;
        }
    }
}
.requestDialog {
    margin-top: 96px;
    .orderDetail {
        border-radius: 70px;
        border: 1px solid #bcbcbc;
        box-shadow: 0px 10px 20px #f2f2f2;
        .head {
            padding: 72px 48px 48px 48px;
            text-align: center;
            .title {
                font-size: 50px;
                font-weight: bold;
                margin-bottom: 48px;
                span {
                    color: #6aaaff;
                    font-size: 40px;
                }
            }
            Button {
                border-radius: 88px;
                text-transform: none;
                font-weight: bold;
            }
        }
        .body {
            padding: 48px 48px 72px 48px;
            background-color: #f8f8f8;
            border-bottom-left-radius: 70px;
            border-bottom-right-radius: 70px;
            .item {
                // margin: 48px 0;
                margin-bottom: 48px;
                font-size: 40px;
                text-align: left;
                .title {
                    margin-bottom: 24px;
                    font-weight: bold;
                }
                .text {
                    color: #9791bf;
                }
            }
        }
    }
    .reasons {
        .title {
            font-size: 40px;
            color: #626262;
            margin-bottom: 48px;
        }
    }
    .reasons {
        margin-top: 72px;
        margin-bottom: 72px;
        .choose {
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            button {
                margin-bottom: 48px;
            }
        }
        .input {
            background-color: #f8f8f8;
            border-radius: 70px;
            padding-top: 48px;
            padding-left: 48px;
            textarea {
                color: #323161;
            }
        }
    }
}

// 弹出框
.orderDialog {
    .dialogContent {
        margin: 96px 0;
    }
}
.orderDialog,
.scrollOrderDialog {
    padding: 72px;
    border-radius: 70px !important;
    .orderDialogTitle {
        padding: 0;
        .MuiTypography-h6 {
            font-size: 55px;
            text-align: center;
        }
        .closeBtn {
            position: absolute;
            right: 36px;
            top: 48px;
        }
    }
    .dialogContent {
        padding: 0;
        padding-right: 8px;
        .contentText {
            color: #323161;
            font-size: 40px;
            margin-bottom: 0;
        }
    }
    .dialogFooter {
        padding: 0;
        padding-top: 72px;
        button {
            width: 384px;
            border-radius: 97px;
            text-transform: none;
        }
        .confirmButton{
            background-color: #ffdc33;
        }
    }
}

// grid layout
.gridShow-3,
.gridShow-2,
.gridShow-1 {
    margin-bottom: 36px;
    display: grid;
    font-size: 40px;
    grid-column-gap: 24px;
    text-align: left;
}
.gridShow-3 {
    grid-template-columns: 2fr 1fr 1.5fr;
    .left {
        justify-self: start;
        color: #6c6c6c;
        font-weight: bold;
        font-size: 40px;
    }
    .center {
        justify-self: right;
        color: #bcbcbc;
    }
    .right {
        white-space: nowrap;
        justify-self: end;
        color: #6c6c6c;
        text-align: right;
    }
}
.gridShow-2 {
    grid-template-columns: repeat(2, 1fr);
    .left {
        justify-self: start;
        color: #626262;
        font-weight: bold;
    }
    .right {
        white-space: nowrap;
        justify-self: end;
        color: #9791bf;
        text-align: right;
    }
}
.gridShow-1 {
    grid-template-columns: repeat(1, 1fr);
    .left {
        justify-self: start;
        color: #626262;
        font-weight: bold;
    }
}
.dishGridShow-3 {
    grid-template-columns: 2fr 0.5fr 1fr !important;
}

.noFoundData {
    margin-top: 600px;
    font-size: 40px;
    color: #bcbcbc;
}
.phoneAirBubbles {
    top: unset !important;
    left: unset !important;
    bottom: 140px !important;
    right: 0 !important;
    &::after {
        right: 24px !important;
    }
}

.orderDishDetail {
    .dish {
        word-break: break-word;
        .additionalFees {
            color: #6c6c6c;
            margin-bottom: 24px;
            margin-left: 40px;
            font-weight: bold;
        }
        .additionalFees .right {
            color: #6c6c6c;
            font-weight: normal;
        }
        .commonArea .left {
            font-size: 30px;
        }

        .titleDot,
        .titleLine {
            margin-left: 40px;
            margin-right: 15px;
        }
        .paddingLeft40 {
            padding-left: 40px;
        }
    }
}
