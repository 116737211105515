.blackGoldPagebody {
    background-color: #282733;
    .IntroductionBox {
        border-radius: 60px;
        height: 980px;
        width: 880px;
        margin: 0 auto;
        position: relative;
        .darkSwipe {
            background: #282733;
            height: 550px;
            .swipeableimg {
                height: 550px;
                padding: 0;
            }
            .itemimg {
                border-bottom-left-radius: 0;
                border-bottom-right-radius: 0;
            }
            .imgbox {
                overflow: hidden;
                width: 880px;
                height: 100%;
            }
            .Stepperdots {
                position: absolute;
                top: 0;
                width: 880px;
                padding: 20px 0;
            }
            .MuiMobileStepper-dotActive {
                background-color: #b07c4d;
            }
        }
        .businessHoursBox {
            height: 120px;
            background-color: #0000004d;
            color: #fff;
            display: flex;
            transform: translateY(-120px);
            position: absolute;
            width: 100%;
            div {
                flex: 1;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 35px;
                .icon {
                    width: 100px;
                    font-size: 35px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    .MuiSvgIcon-root {
                        font-size: 50px;
                    }
                }
            }
        }
        .restaurantInfoBox {
            background-color: #fff;
            border-bottom-left-radius: 60px;
            border-bottom-right-radius: 60px;
            padding: 48px;
            height: 358px;
            display: flex;
            .imgbox {
                width: 240px;
                flex-direction: column;
                height: 240px;
                .restaurantLogo {
                    width: 100%;
                    border-radius: 50px;
                    height: 100%;
                    object-fit: cover;
                }
            }
            .introductionbox {
                width: calc(100% - 240px - 48px);
                height: 100%;
                padding-left: 48px;
            }
            .chipbox {
                margin-top: 10px;
                text-align: left;
                overflow-x: scroll;
                white-space: nowrap;
                div {
                    margin-right: 24px;
                    margin-top: 5px;
                }
                .MuiChip-root {
                    background-color: #b07c4d40;
                    color: #b07c4d;
                    padding: 0 15px;
                }
            }
        }
    }
    .businessLayout {
        display: flex;
        margin: 48px 72px;
    }
    .restaurantCouponPackage {
        display: flex;
        overflow-x: scroll;
        overflow-y: hidden;
        margin: 0 24px;
        .blackGoldCouponItem {
            margin: 0 24px;
        }
        .ruleText {
            width: 100%;
        }
    }
    .featuredDishesTitle {
        text-align: left;
        font-size: 45px;
        color: #caa376;
        margin: 24px 72px;
        font-family: 'Krub';
    }
    .featuredDishes {
        display: flex;
        margin-left: 48px;
        margin-bottom: 224px;
        overflow-x: scroll;
        overflow-y: hidden;
        .featuredDishesLayout {
            display: flex;
        }
    }
    
}
