.checkoutCouponComponent {
    -moz-user-select: none; /* 火狐 */
    -webkit-user-select: none; /* 谷歌 */
    -ms-user-select: none; /* IE */
    user-select: none;
    .iconHeadBox {
        padding-bottom: 0;
    }
    .noCouponBox {
        height: 48px !important;
    }
    .scrollBox {
        width: 100%;
        .allCouponsBox {
            display: flex;
            overflow: hidden;
            float: left;
            // overflow-x: scroll;
            // overflow-y: hidden;
            min-width: 100%;
            height: 450px;
            .couponItemBox {
                margin: 0 24px;
                display: flex;
                align-items: center;
                justify-content: center;
                .couponItem {
                    margin: unset;
                    transition: all cubic-bezier(0, 0, 0, 1) 0.35s;
                }
                &:nth-of-type(1) {
                    margin-left: 0;
                }
                .ruleText {
                    width: 80%;
                }
            }
            .couponItemBubbless {
                top: -65px;
                background-color: #ffdc33;
                z-index: 1;
                span {
                    color: #000;
                }
                &::after {
                    border-top: 24px solid #ffdc33;
                }
            }
        }
    }

    .redeemCouponBox {
        text-align: left;
        .redeemCoupon {
            font-size: 40px;
            color: #5867b4;
        }
    }
}

.coupon-change-style {
    background-color: #fff;
    opacity: 0.5;
}
