.checkoutCartBox {
    .dineIn-head-box {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 48px 24px 32px;
        font-size: 30px;
    }

    .dishesList {
        .dishItem {
            margin: 8px;
            margin-bottom: 16px;
            background-color: #fff;
            border-radius: 30px;
            box-shadow: 0px 10px 25px 0px #00000012;
            padding: 8px;
            position: relative;

            .dishItemContent {
                display: flex;
                justify-content: flex-start;
                align-items: flex-start;
                height: auto;
                position: relative;
                padding-top: 10px;
                padding-bottom: 10px;
                margin: 15px;

                .right-content {
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    flex: 1;

                    .titleBox {
                        display: flex;
                        justify-content: space-around;
                        align-items: flex-start;
                        flex: 1;
                        flex-direction: column;
                        margin-left: 30px;
                        height: 100%;

                        div {
                            text-align: left;
                        }

                        .title {
                            font-size: 40px;
                            color: #333;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            word-break: break-word;
                            width: 100%;
                            -webkit-line-clamp: 2;
                            -webkit-box-orient: vertical;
                            display: -webkit-box;
                        }

                        .controls-bar {
                            display: flex;
                            position: relative;
                        }

                        .mdRateReviewBtn {
                            padding: 0;
                            margin-top: 20px;
                            margin-bottom: 20px;
                            margin-right: 20px;
                            width: 72px;
                            height: 72px;
                            background-color: #e3e7ee;

                            .mdRateReviewIcon {
                                width: 60%;
                                height: 60%;
                                color: #000;
                            }
                        }

                        .couponIconBox {
                            margin-top: 20px;
                            margin-bottom: 20px;
                            margin-right: 20px;
                            background-color: #e3e7ee;
                            border-radius: 30px;
                            display: flex;
                            align-items: center;

                            .couponIconBtn {
                                padding: 0;
                                width: 72px;
                                height: 72px;

                                .MdLocalActivity {
                                    width: 60%;
                                    height: 60%;
                                    color: #000;
                                }
                            }

                            .iconText {
                                color: #5867b4;
                                font-size: 20px;
                                padding-right: 20px;
                            }
                        }

                        .price {
                            font-size: 45px;
                            color: #000;
                            text-align: center;
                        }

                        .collapse {
                            .chip {
                                justify-content: center;
                                align-items: center;
                                text-align: center;
                                background-color: #e7ecf4;
                                margin-bottom: 20px;
                            }
                        }

                        .dish-tip-box {
                            display: flex;
                            align-items: center;
                            position: absolute;
                            bottom: 0;
                            right: 0;
                            gap: 16px;

                            .dish-tip {
                                text-align: right;
                                font-size: 30px;
                                cursor: pointer;
                                display: flex;
                                align-items: center;
                            }

                            .number {
                                margin: 0 32px;
                                font-size: 35px;
                                font-weight: bold;
                                color: #000;
                            }

                            .delete-iconBtn {
                                background-color: #acacac;
                                padding: 0;
                                width: 90px;
                                height: 90px;

                                .minusBtn {
                                    width: 60%;
                                    height: 60%;
                                    color: #FFF;
                                }

                            }

                        }
                    }
                }

                .dishImgbox {
                    width: 200px;
                    height: 200px;
                    background: #f5f5f5;
                    border-radius: 40px;
                    position: relative;

                    .dishImg {
                        min-width: 100%;
                        max-width: 100%;
                        height: 100%;
                        object-fit: cover;
                        border-radius: 40px;
                    }

                    .MdFastfood-icon-box {
                        width: 70px;
                        height: 70px;
                        position: absolute;
                        top: -22px;
                        left: -22px;
                        background-color: #ffdc33;
                        color: #000;
                        border-radius: 50%;
                        display: flex;
                        align-items: center;
                        justify-content: center;

                        .MdFastfood-icon {
                            width: 45px;
                            height: 45px;
                        }
                    }
                }

                .usedCouponsBox {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    flex-direction: column;
                    width: 170px;
                    height: 100%;
                    border-right: 3px dashed #cecece;

                    .icon {
                        width: 64px;
                        height: 64px;
                        color: #5867b4;

                        svg {
                            width: 100%;
                            height: 100%;
                        }
                    }

                    .iconText {
                        color: #5867b4;
                        font-size: 20px;
                    }
                }

                .priceNumber {
                    position: absolute;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    bottom: 0;
                    right: 0;

                    .number {
                        margin: 0 32px;
                        font-size: 35px;
                        font-weight: bold;
                        color: #000;
                    }

                    .iconBtn {
                        padding: 0;
                        width: 90px;
                        height: 90px;

                        .minusBtn {
                            width: 100%;
                            height: 100%;
                            color: #acacac;
                        }

                        .plusBtn {
                            width: 100%;
                            height: 100%;
                            color: #ffdc33;
                        }
                    }

                    .disabledIconBtn {
                        svg {
                            color: #e8e7eb !important;
                        }
                    }
                }
            }

            .dishRemarks {
                .remarkTextBox {
                    display: flex;
                    padding: 24px;

                    .remarkChip {
                        background-color: #e7ecf4;
                        font-size: 40px;
                        color: #a2afc3;
                        width: 47px;
                        height: 47px;
                    }
                }

                .dishRemarksHead {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    padding-left: 48px;

                    .title {
                        font-size: 40px;
                        color: #5867b4;
                    }
                }

                .close {
                    color: #cecece !important;
                    width: 72px !important;
                    height: 72px !important;
                    margin: 0 8px;
                }

                .check {
                    color: #ffdc33 !important;
                    width: 72px !important;
                    height: 72px !important;
                    margin: 0 8px;
                }

                .textareaBox {
                    flex: 1;

                    textarea {
                        width: 100%;
                        background-color: #fff;
                        border-width: 0;
                        min-height: 176px;
                        padding: 48px;
                        box-sizing: border-box;
                        font-size: 40px;
                        color: #5867b4;
                    }
                }
            }

            .condimentTagBox {
                display: flex;
                padding-bottom: 32px;
                overflow: scroll;
                flex-wrap: wrap;

                .condimentTag {
                    background-color: #e7ecf4;
                    font-size: 30px;
                    padding: 12px 16px;
                    color: #a2afc3;
                    border-radius: 60px;
                    display: flex;
                    align-items: center;
                    vertical-align: middle;
                    justify-content: center;
                    margin: 8px 8px 0 0;

                    .condimentTagText {
                        white-space: nowrap;
                    }
                }
            }

            .set-meal-dishes-box {
                border-top: 2px dashed #e8e8e8;
                padding: 24px;

                .set-meal-dishes-box-tip-text {
                    font-size: 36px;
                    color: #606060;
                    text-align: left;
                    margin-bottom: 16px;
                }

                .set-meal-dishes-list {
                    display: flex;
                    flex-wrap: wrap;

                    .set-meal-dish-item {
                        width: 50%;
                        overflow: hidden;
                        font-size: 36px;
                        color: #8e8e8e;
                        display: flex;
                        align-items: center;
                        align-content: flex-start;
                        box-sizing: border-box;
                        padding-bottom: 16px;

                        .dot {
                            width: 15px;
                            height: 15px;
                            background-color: #d7d7d7;
                            border-radius: 50%;
                            margin-right: 16px;
                        }

                        .setmenu-extra-price-text {
                            color: #d3a500;
                        }

                        .setmenu-dish-name {
                            flex: 1;
                            white-space: nowrap;
                            overflow: hidden;
                            text-align: left;
                            text-overflow: ellipsis;
                        }
                    }

                    > :nth-child(2n-1) {
                        padding-right: 16px;
                    }
                }
            }
        }
    }

    .errorText {
        display: inline;
        font-size: 30px;
        padding: 10px 20px;
        color: #fd7b88;
        text-align: left;
        display: flex;
        align-items: center;
        justify-content: flex-start;
    }
}

// 菜品优惠券气泡
.cartAirBubbless {
    top: unset !important;
    bottom: 100px !important;
    left: 100px !important;
    word-break: keep-all;

    &::after {
        left: 25px !important;
        right: unset !important;
    }
}