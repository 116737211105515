.dishDetailContainer {
    width: 100%;
    .mdClearIco {
        position: absolute;
        right: 48px;
        top: 48px;
        width: 56px;
        height: 56px;
        background: rgba(255, 255, 255, 0.7);
        border-radius: 100%;
        padding: 8px;
        color: #666;
        z-index: 12;
    }
    .dishImg {
        position: relative;
        width: 100vw;
        height: 675px;
        // padding-bottom:62.5%;
        background: #bcbcbc;
        &::after {
            content: "";
            display: block;
            margin-top: 62.5%;
        }
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
    .dishMainContainer {
        position: fixed;
        display: flex;
        flex-direction: column;
        top: 620px;
        bottom: 0;
        width: 100%;
        border-radius: 70px;
        background: rgba(255, 255, 255, 1);
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        -moz-box-shadow: 0px 15px 25px rgba(0, 0, 0, 0.141176470588235);
        -webkit-box-shadow: 0px 15px 25px rgba(0, 0, 0, 0.141176470588235);
        box-shadow: 0px 15px 25px rgba(0, 0, 0, 0.141176470588235);
        z-index: 10;
        .name {
            font-family: "Krub Bold", "Krub Regular", "Krub";
            font-weight: 700;
            font-style: normal;
            font-size: 50px;
            color: #000000;
            text-align: center;
            line-height: 72px;
            margin-top: 52px;
            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
            text-overflow: ellipsis;
            padding: 0 35px;
            word-wrap: break-word;
        }
        .logoList {
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 31px 0 37px;
            padding: 10px;
            position: relative;
            .MuiTooltip-popper > div {
                margin: 0;
            }
            li {
                width: 80px;
                height: 80px;
                .logo {
                    width: 100%;
                    height: 100%;
                }
            }
        }
        .priceContainer {
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 0 100px;
            .price {
                font-family: "Krub Bold", "Krub Regular", "Krub";
                font-weight: 700;
                font-style: normal;
                font-size: 60px;
                color: #ffdc33;
                text-align: center;
                line-height: 72px;
            }
            .symbol {
                max-height: 80px;
                font-family: "Krub Medium", "Krub Regular", "Krub";
                font-weight: 500;
                font-style: normal;
                font-size: 40px;
                color: #5867b4;
                text-align: center;
                border: 1px solid #5867b4;
                border-radius: 100px;
                padding: 1px 10px;
                margin-left: 27px;
                overflow-y: auto;
            }
        }
        .option {
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 52px 0 48px;
            &::after {
                position: absolute;
                content: "";
                left: 0;
                right: 0;
                height: 2px;
                width: 100vw;
                background: #989eb6;
                z-index: -1;
            }
            p {
                width: 304px;
                height: 72px;
                font-family: "Krub SemiBold", "Krub Regular", "Krub";
                font-weight: 600;
                font-style: normal;
                font-size: 40px;
                text-align: center;
                line-height: 72px;
                color: #000000;
                background: #ffdc33;
                border-radius: 50px;
                &:nth-of-type(2) {
                    margin-left: 29px;
                }
            }
            .isIngredients {
                background: #f2f2f7;
                color: #989eb6;
            }
        }
        .description {
            flex: 1;
            width: 688px;
            font-size: 40px;
            color: #bcbcbc;
            line-height: 72px;
            word-break: break-word;
            margin: 0 auto;

            overflow: scroll;
        }
        .allergen {
            flex: 1;
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            width: 688px;
            margin: 0 auto;
            overflow: auto;
            li {
                display: flex;
                flex-direction: column;
                align-items: center;
                flex: 1;
                margin-bottom: 34px;
                .placeholderBox {
                    position: relative;
                    width: 114px;
                    margin-bottom: 34px;
                    &::after {
                        content: "";
                        display: block;
                        margin-top: 100%;
                    }
                    img {
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                    }
                }

                p {
                    font-family: "Krub Medium", "Krub Regular", "Krub";
                    width: 216px;
                    word-break: break-word;
                    font-weight: 500;
                    font-style: normal;
                    font-size: 40px;
                    color: #bcbcbc;
                    text-align: center;
                    line-height: 48px;
                }
            }
        }
        button {
            // left: calc(50% - 386px);
            display: flex;
            align-items: center;
            justify-content: center;
            width: 792px;
            height: 144px;
            background: inherit;
            background-color: rgba(255, 220, 51, 1);
            border: none;
            border-radius: 88px;
            -moz-box-shadow: 0px 10px 20px rgba(134, 113, 63, 0.109803921568627);
            -webkit-box-shadow: 0px 10px 20px rgba(134, 113, 63, 0.109803921568627);
            box-shadow: 0px 10px 20px rgba(134, 113, 63, 0.109803921568627);
            font-size: 50px;
            margin: 0 auto 50px;
            .mdAddIco {
                width: 75px;
                height: 75px;
            }
            span {
                font-family: "Krub Medium", "Krub Regular", "Krub";
                font-weight: 500;
                font-style: normal;
                font-size: 50px;
                color: #22223a;
                text-align: center;
                line-height: 72px;
            }
        }
        .disButton {
            background-color: #d5d5d9;
        }
    }
}
