    .defaultThemeAddress{
        .delivery-address-main {
            display: flex;
            flex: 1;
            width: 100%;
            flex-direction: column;
            background-color: #F8F8F8;
            z-index: 2;
        }

        .delivery-address-main .createAddress {
            height: auto;
        }

        .delivery-font-style-BC {
            font-family: 'Krub';
            font-weight: 400;
            font-style: normal;
            font-size: 40px;
            color: #BCBCBC;
        }

        .delivery-font-style-6C {
            font-family: 'Krub';
            font-weight: 400;
            font-style: normal;
            font-size: 40px;
            color: #6c6c6c;
        }

        .delivery-address-content {
            height: calc(100% - 196px - 50px);
            margin: 0 auto 50px;
            .delivery-address-list {
                height: calc(100% - 200px);
                overflow: auto;
                border-radius: 40px;
            }
            .phone-main {
                .phone-insert-in {
                    width: 600px;
                    padding: 20px;
                    background: #fff;
                    justify-Content: center;
                    align-Items: center;
                    border: none;
                    height: 100%;
                    color: #394474;
                    font-Size: 50px;
                }
            }
            .input-main {
                position: relative;
                display: flex;
                flex-Direction: row;
                background-Color: #fff;
                align-Items: center;
                justify-Content: center;
                height: 144px;
                border-width: 1px;
                border-style: solid;
                border-color: rgba(207, 207, 207, 1);
                border-radius: 88px;
                overflow: hidden;
            }
            .confirm {
                width: 792px;
                height: 144px;
                border-radius: 88px;
                background-color: #FFDC33 !important;
                font-size: 50px;
                font-family: 'Arial Normal', 'Arial';
                font-weight: 400;
            }
            .notChoose {
                width: 792px;
                height: 144px;
                border-radius: 88px;
                background-color: #BCBCBC !important;
                color: #fff;
                font-size: 50px;
                font-family: 'Arial Normal', 'Arial';
                font-weight: 400;
            }
            .main-body {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                height: 100%;
                padding-bottom: 50px;
                align-items: center;
                .address-tit {
                    font-weight: 600;
                    font-size: 40px;
                    color: #9791BF;
                }
                .address-msg {
                    font-weight: 400;
                    font-size: 40px;
                    color: #9791BF;
                }
                .address-main-bg {
                    width: 936px;
                    border-radius: 40px;
                    background-color: #fff;
                    padding: 60px 40px;
                }
                .address-card {
                    display: flex;
                    flex-direction: column;
                    justify-content: space-evenly;
                    width: 840px;
                    min-height: 501px;
                    border-radius: 40px;
                    margin-bottom: 50px;
                    padding:24px;
                    word-break: break-word;
                }
            }
        }

        // 修改地址弹窗
        .deliveryAddressModal {
            .account-tit {
                color: #9791BF;
                font-weight: 600;
                font-style: normal;
                font-size: 40px;
            }
            .dialogName {
                position: relative;
                display: flex;
                align-items: center;
                justify-content: flex-end;
                margin-bottom: 6.667vw;
                .fontStyle {
                    font-size: 55px;
                    width: 100%;
                    text-align: center;
                }
                .close {
                    position: absolute;
                    top: 0;
                    right: 0;
                }
                .third-party-main {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    .description {
                        font-family: 'Arial Normal', 'Arial';
                        font-size: 40px;
                        color: #BCBCBC;
                        text-align: left;
                        font-weight: 400;
                    }
                    .tit {
                        font-size: 55px;
                    }
                    .tit-info {
                        font-size: 40px;
                        color: #BCBCBC;
                        text-align: left;
                        font-weight: 400;
                        text-align: center;
                        margin: 10px 0;
                    }
                }
                .thirdPartyIcon img {
                    width: 100%;
                }
                .mark-remark {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 129px;
                    height: 59px;
                    background-color: #6AAAFF;
                    color: #fff;
                    font-family: 'Krub Medium', 'Krub Regular', 'Krub';
                    font-weight: 500;
                    font-style: normal;
                    font-size: 40px;
                    border-radius: 112px;
                    margin-right: 32px;
                    padding: 0 50px;
                }
            }
        }

        .deliveryAddressModal .MuiDialog-paperWidthSm {
            width: 952px;
            height: 1800px;
            border-radius: 42px;
            // 确认按钮
            .confirm {
                width: 100%;
                height: 144px;
                border-radius: 88px;
                background-color: #FFDC33;
                font-size: 50px;
                font-family: 'Arial Normal', 'Arial';
                font-weight: 400;
                margin-top: 50px;
                text-transform: capitalize;
            }
        }

        .editInput {
            height: 144px;
            background-color: rgba(88, 103, 180, 0);
            box-sizing: border-box;
            border-width: 3px;
            border-style: solid;
            border-color: rgba(207, 207, 207, 1);
            border-radius: 88px;
            font-size: 40px !important;
            color: #9791BF !important;
            padding: 0 40px;
        }
    }
