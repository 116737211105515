.BlackGoldCouponList {
    z-index: 999;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: #282733;

    .backgroundTransparent {
        background-color: transparent !important;
        color: #fff;
        .title-text {
            color: #fff;
        }
        .confrimicon {
            color: #fff;
        }
    }
    .content::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background: #000;
    }
    .content::-webkit-scrollbar-track-piece {
        border-radius: 10px;
        background: #282733;
    }
    .content {
        height: calc(100% - 196px - 288px);
        overflow-y: scroll;
        overflow-x: hidden;
        .restaurantName {
            text-align: left;
            font-size: 48px;
            width: 900px;
            margin: 0 auto 48px;
            font-family: "kurb Medium";
            color: #caa376;
        }
        .isCheckCoupon {
            width: 900px;
            margin: 0 auto 30px;
            padding: 0px;
            position: relative;
            .couponItem {
                width: 100%;
                margin-bottom: 30px;
                transform: translate3d(72px, 0px, 0px);
                transition: transform 0.1s;
            }
        }
        .checkedItem {
            background-color: #c0a58d;
        }
        .couponItem:active {
            background-color: #c0a58d;
        }
        .disabledItem {
            opacity: 0.5;
        }
        .disabledItem:active {
            background-color: #c0a58d;
        }
        .doneicon {
            width: 48px;
            height: 48px;
            border-radius: 48px;
            font-weight: bold;
            color: #ffdc33;
            background: #3d3c69;
        }
        .placeholderBox {
            position: absolute;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 48px;
            height: calc(280px - 48px);
            padding: 24px 0;
            margin-right: 24px;
            border-radius: 48px;
            margin-bottom: 30px;
        }
        .row1 {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            width: 100%;
            height: 50%;
            font-family: "kurb Medium";
            font-size: 50px;
            color: #fff;
            .col1 {
                width: 40%;
                text-align: left;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
                word-break: break-word;
                line-height: 60px;
            }
            .col2 {
                width: 60%;
                text-align: left;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
                word-break: break-word;
                line-height: 60px;
            }
        }
        .row2 {
            font-size: 30px;
        }
        .description {
            width: 900px;
            margin: 0 auto 50px;
            font-size: 30px;
            color: #77614d;
            text-align: left;
            transition: transform 0.1s;
        }
        .checkedDescription {
            color: #caa476;
            transform: translate3d(72px, 0px, 0px);
            transition: transform 0.1s;
        }
        .notfind {
            width: 100vw;
            height: calc(100% - 196px);
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 60px;
            font-family: Medium;
            color: #fff;
        }
    }
    .footerBox {
        height: 288px;
        display: flex;
        .footerContent {
            width: 900px;
            margin: 72px auto;
            display: flex;
            justify-content: space-between;
            .selectText {
                height: 100%;
                font-size: 40px;
                color: #b5b4b4;
                font-family: "kurb Medium";
                width: calc(100% - 328px);
                display: flex;
                align-items: center;
            }
            .number {
                color: #caa376;
                padding: 10px;
            }
            .confirmbutton {
                width: 328px;
                height: 144px;
                .checkoutBtn {
                    width: 100%;
                    height: 100%;
                    flex: 1;
                    font-weight: 500;
                    font-style: normal;
                    text-align: center;
                    height: 144px;
                    background: inherit;
                    background-color: #b07c4d;
                    color: #fff;
                    border-radius: 88px;
                    font-size: 50px;
                    border: none;
                }
                .unCheckBth {
                    background-color: #bcbcbc;
                }
            }
        }
    }

    ::-webkit-scrollbar {
        display: block;
    }
    .blackGoldGetCuoponBox {
        .getCuoponBox {
            background-color: #282733;
            color: #fff;
            .cuoponBoxRow {
                background-color: #282733;
                .cuoponImg {
                    filter: invert(100%);
                }
            }
            .cuoponBoxRow:active {
                background-color: #b9aea4;
                color: #282733;
                .cuoponImg {
                    filter: invert(0%);
                }
            }
            &::after {
                border-bottom: 23px solid #282733;
            }
        }
    }
}
.blackGoldInsertCode {
    .MuiDialog-paper {
        background-color: #282733;
        color: #fff;
        .dialogContent .ok {
            height: 120px;
            background-color: #3e3c4c;
            color: #b07c4d;
            border-radius: 30px;
        }

        .dialogContent .cancel {
            height: 120px;
            background-color: #3e3c4c;
            color: #8c949f;
            border-radius: 30px;
        }

        input {
            color: #b07c4d;
            background-color: #3e3c4c;
        }
    }
}
