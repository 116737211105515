.bookingBoxSendDialog {
    z-index: 999 !important;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    font-family: "Krub Medium", "Krub Regular", "Krub";
    .MuiDialogContent-root {
        padding: 48px;
    }
    .MuiDialog-paperWidthSm {
        width: 1104px;
        /* height: 585px; */
        border-radius: 42px;
    }

    .dialogName {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 72px;
    }

    .dialogName .close {
        position: absolute;
        top: 0;
        right: 0;
    }

    .dialogContent {
        display: flex;
        flex-direction: column;
        /* height: 500px; */
    }
    .contentText {
        text-align: center;
        font-size: 45px;
        margin: 58px 0;
        .p1 {
            color: #323161;
            margin:0 0 10px ;
        }
        .p2 {
            color: #323161;
        }
        .p3 {
            color: #989eb6;
            font-size: 40px;
            margin-top: 58px;
        }
        .here{
            color: #323161;
            font-weight: bold;
        }
    }
    .dialogContent .ok {
        // width: 354px;
        height: 130px;
        background-color: #ffdc33;
        color: #000;
        border-radius: 88px;
    }
    .insertCodeBtn {
        width: 100%;
        text-transform: capitalize;
        font-size: 50px;
    }

    .dialogName .fontStyle {
        font-size: 55px;
        width: 100%;
    }
}
