.myReservations {
    .myOrderPageContainer {
        flex: 1;
        .switchStatusBox {
            width: 100%;
            overflow-x: scroll;
        }
    }
    .myOrderPageContainer .switchStatus {
        white-space: pre;
        // width: 200%;
        display: inline-block;
        grid-column-gap: 0px;
        margin: 48px 0;
        button {
            margin: 10px 0;
            min-width: 0;
            padding: 0 50px;
            margin-right: 50px;
        }
        button.active {
            height: 82px;
            background-color: #ffdc33;
            color: #000;
        }
        .MuiButton-contained {
            background-color: #f2f2f7;
            color: #989eb6;
        }
    }
    .myReservationsInfo {
        .time {
            color: #394474;
            font-size: 40px;
        }
        .date {
        }
    }
}
