.iconHeadBox {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 48px 24px 32px;
    .icon {
        width: 45px;
        height: 45px;
        color: #000;
        padding: 16px;
        background-color: #ffdc33;
        border-radius: 64px;
        margin-right: 48px;
        svg {
            width: 100%;
            height: 100%;
        }
    }
    .titleBox {
        display: flex;
        justify-content: flex-start;
        flex: 1;
        font-size: 40px;
        .title {
            font-weight: bold;
            color: #000;
            margin-right: 24px;
            text-align: left;
        }
        .subTitle {
            color: #b4b5b5;
        }
    }
    .action {
        font-size: 40px;
        color: #323161;
        display: flex;
        align-items: center;
        justify-content:center;
        svg {
            width: 56px;
            height: 56px;
            color: #000;
        }
    }
}
