.PleaseLoginDialog {
    z-index: 999 !important;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    .MuiDialog-paper {
        width: 1104px;
        border-radius: 42px;
    }

    .dialogName {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 72px;
    }

    .dialogName .close {
        position: absolute;
        top: 0;
        right: 0;
    }

    .insertCode {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: space-between;
        align-items: center;
        color: #bcbcbc;
        .leftInput {
            width: 48%;
        }
        .rightInput {
            width: 48%;
        }
    }

    .dialogContent {
        display: flex;
        flex-direction: column;
        /* height: 500px; */
    }

    .dialogContent .ok {
        width: 354px;
        height: 144px;
        background-color: #ffdc33;
        color: #000;
        border-radius: 88px;
    }

    .dialogContent .cancel {
        width: 354px;
        height: 144px;
        background-color: #5867b4;
        color: #fff;
        border-radius: 88px;
    }

    .btn {
        width: 100%;
        text-transform: capitalize;
    }

    .dialogName .fontStyle {
        font-size: 55px;
        width: 100%;
        padding-right: 1em;
    }
}

.blackGoldPleaseLoginDialog {
    .MuiDialog-paper {
        background-color: #282733;
    }
    .dialogName {
        color: #fff;
    }
    .dialogContent .ok {
        background-color: #b07c4d;
        color: #fff;
    }

    .dialogContent .cancel {
        background-color: #b07c4d;
        color: #fff;
    }
}
