.MyFidelityPage {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    .main {
        background-color: #ffdc33;
        width: 100%;
        height: calc(100% - 196px);
        overflow: hidden;
        font-family: Medium;

        .buttonbox {
            height: 120px;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            padding: 24px 48px;

            .button {
                height: 94px;
                font-size: 40px;
                color: #826143;
                background-color: #f9cd31;
                border-radius: 50px;
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 0 48px;
                margin-right: 48px;
            }

            .onSelect {
                opacity: 0.8;
                background-color: #fff;
                color: #000;
            }
        }

        .content {
            height: calc(100% - 120px);
            background-color: #fff;
            border-top-left-radius: 70px;
            border-top-right-radius: 70px;
            padding: 48px 0;
            display: flex;
            flex-direction: column;
            overflow: hidden;

            .inputBox {
                height: 100px;
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 0 72px 48px;

                .inputContainer {
                    position: relative;
                    flex: 1;
                    margin-right: 24px;

                    input {
                        box-sizing: border-box;
                        width: 100%;
                        border: 0px solid #cfcfcf;
                        height: 100px;
                        border-radius: 100px;
                        padding: 12px 40px;
                        background-color: #f2f2f7;
                        font-size: 40px;
                    }

                    input::-webkit-input-placeholder {
                        font-size: 40px;
                        color: #bdbdbd;
                    }

                    .searchIcon {
                        display: flex;
                        align-items: center;
                        position: absolute;
                        top: 10px;
                        right: 10px;
                        font-size: 60px;
                        width: 80px !important;
                        height: 80px !important;
                        padding: 0;
                        color: #000;
                    }
                }

                .confrimicon {
                    width: 80px !important;
                    height: 80px !important;
                    color: #000;
                    padding: 0;
                }
            }

            .couponList {
                overflow-y: scroll;
                height: calc(100% - 148px - 96px);

                .MyFidelityCuoponBox {
                    .getCuoponBox {
                        top: calc(196px + 348px) !important;
                        right: 70px !important;
                    }
                }
            }

            .scoresList {
                overflow-y: scroll;
                flex: 1;
                padding-bottom: 700px;
                position: relative;

                .scoresBox {
                    height: 320px;
                    padding:12px 24px;
                    margin: 0 72px 72px;
                    border-radius: 50px;
                    background-color: #000;
                    color: #fff;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    flex-direction: column;

                    .row {
                        color: #ffdc33;
                    }

                    .nickname {
                        font-size: 30px;
                        margin-bottom: 24px;
                    }

                    .value {
                        font-size: 100px;
                        font-weight: bold;
                    }

                    .clear-rule {
                        font-size: 25px;
                        margin-top: 16px;
                        color: #cecece;
                        text-align: left;
                        width: 100%;
                    }

                    .groupReferralScores {
                        margin-top: 16px;
                        font-size: 30px;
                        color: #cecece;
                    }
                }
            }

            .titleName {
                text-align: left;
                font-size: 40px;
                width: 900px;
                margin: 0 auto 48px;
                font-family: "kurb Medium";
            }

            .notfind {
                display: flex;
                align-items: center;
                justify-content: center;
                height: calc(100% - 148px - 96px);
                font-size: 50px;
            }

            .referralList {
                flex: 1;
                overflow: auto;
                padding: 0 0 60px;

                .referralItem {
                    background-color: #fff;
                    border: 1px solid #e8e8ec;
                    border-radius: 60px;
                    margin: 20px 48px;
                    padding: 48px 30px;

                    .top {
                        color: #bcbcbc;
                        font-size: 30px;
                        display: flex;
                        justify-content: space-between;

                        .left {
                            display: flex;
                            align-items: center;

                            img {
                                width: 48px;
                                height: 48px;
                                margin-right: 30px;
                            }
                        }

                        .right {}
                    }

                    .bottom {
                        color: #333;
                        font-size: 40px;
                        text-align: left;
                        margin: 20px 0;
                        line-height: 60px;
                    }
                }
            }
        }
    }
}