// $ 修改个人信息
.personal-information-main {
  display: flex;
  flex: 1;
  width: 100%;
  flex-direction: column;
  background-color: #f8f8f8;
  z-index: 2;
  em {
    color: rgb(255, 0, 0);
  }
}
.personal-information-content {
  height: calc(100% - 196px);
  margin: 0 auto;
  overflow: hidden;
  .main-body {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    padding-bottom: 50px;
    align-items: center;
    .MuiButton-contained:hover {
      background-color: #ffdc33 !important;
    }
    .item-style {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      width: 792px;
      margin: 0 auto;
      .tag-style {
        font-weight: 600;
        font-style: normal;
        font-size: 40px;
        color: #9791bf;
      }
    }
  }

  .input-main {
    color: #9791bf;
    font-size: 4.63vw;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    background-color: #fff;
    align-items: center;
    height: 144px;
    border-width: 1px;
    border-style: solid;
    border-color: rgba(207, 207, 207, 1);
    border-radius: 88px;
    overflow: hidden;
    width: 100%;
    margin: 48px auto;
    padding: 48px 48px;
  }

  .main-icon {
    width: 168px;
    height: 168px;
    border-radius: 50%;
    background-color: #ffdc33;
    margin: 0 auto 48px auto;
    font-size: 70px;
    line-height: 168px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

// $ 修改密码
.change-password-main {
  display: flex;
  flex: 1;
  width: 100%;
  flex-direction: column;
  background-color: #f8f8f8;
  z-index: 2;
}

.change-password-content {
  height: calc(100% - 196px);
  margin: 0 auto;
  overflow: hidden;
  .main-body {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    padding-bottom: 50px;
    align-items: center;
    .item-style {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      width: 792px;
      margin: 0 auto;
      .tag-style {
        font-weight: 600;
        font-style: normal;
        font-size: 40px;
        color: #9791bf;
      }
    }
  }
  .password-insert {
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: 144px;
    width: 792px;
    margin: 48px auto 24px;
    background-color: #fff;
    border-width: 1px;
    border-style: solid;
    border-color: rgba(207, 207, 207, 1);
    border-radius: 88px;
    padding: 0px 60px;
  }
}

// $ 密码输入组件样式
.signin-password-input {
  width: 60%;
  background: #fff;
  justify-content: center;
  align-items: center;
  border: none;
  height: 100%;
  color: #9791bf;
  font-size: 50px;
  margin-left: 24px;
}

.signin-label {
  font-size: 18px;
}

.signin-visibility {
  color: #9791bf;
  width: 48px;
  height: 48px;
}
