.shoppingBasket {
    height: 100%;
    width: 816px;
    background-color: #eeeeee;
    border-radius: 40px 0 0 40px;
    position: relative;
    .price {
        box-sizing: border-box;
        height: 192px;
        width: 100%;
        border-radius: 40px 0 0 0px;
        background-color: #f8f8f8;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        padding: 0 48px 0 88px;
        .menuPrice {
            color: #333333;
            font-size: 40px;
            font-weight: bold;
            font-family: "Krub";
        }
        .close {
            display: flex;
            flex-direction: column;
            align-items: center;
            color: #000000;
            span {
                font-size: 30px;
                color: #000000;
            }
            .MuiIconButton-root {
                padding: 0;
                width: 80px;
                height: 80px;
                .MuiIconButton-label {
                    margin-bottom: 0;
                }
            }
            .closeIcon {
                width: 80px;
                height: 80px;
                color: #000000;
            }
        }
    }
    .basketDishs {
        box-sizing: border-box;
        width: 100%;
        height: calc(100% - 500px);
        overflow: auto;
        padding: 36px 24px 0 24px;
        .categoryTitle {
            margin: 40px 0;
            display: flex;
            flex-direction: row;
            overflow: hidden;
            h6 {
                color: #797979;
                font-size: 35px;
                font-family: "Krub";
                margin-right: 20px;
                max-width: 80%;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }
            span {
                color: #797979;
                font-size: 35px;
                font-family: "Krub";
                font-weight: bold;
            }
            .handleRed {
                color: #f65252;
            }
            .checkIcon {
                margin-left: 15px;
                color: #989eb6;
                height: 36px;
                width: 36px;
                border-radius: 18px;
            }
        }
        .handleGreen {
            span {
                color: #02821e;
            }
        }
        ul {
            li {
                flex: none;
                margin-bottom: 24px;
            }
        }
    }
    .footer {
        display: flex;
        justify-content: center;
        position: absolute;
        width: 100%;
        bottom: 70px;
        .addCart {
            width: 726px;
            height: 144px;
            border: none;
            border-radius: 88px;
            background-color: #ffdc33;
            display: flex;
            align-items: center;
            justify-content: center;
            .mdAddIco {
                height: 72px;
                width: 72px;
                margin-right: 40px;
            }
            span {
                font-size: 40px;
                font-weight: bold;
                font-family: 'Krub';
            }
        }
        .disAddCart{
            background-color: #D5D5D9;
        }
    }
}
