.paymentSidebar {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 48px 0;
    .line {
        display: inline-block;
        width: 3px;
        height: 48px;
        background: #bcbcbc;
        margin: 25px 0;
    }
    .initIco {
        width: 72px;
        height: 72px;
        color: #989eb6;
        padding: 24px;
    }
    .suspendedBox {
        box-sizing: border-box;
        width: 120px;
        height: 120px;
        background: inherit;
        background-color: rgba(255, 255, 255, 1);
        border: none;
        border-radius: 40px;
        -moz-box-shadow: 0px 10px 15px rgba(242, 242, 242, 0.996078431372549);
        -webkit-box-shadow: 0px 10px 15px rgba(242, 242, 242, 0.996078431372549);
        box-shadow: 0px 10px 15px rgba(242, 242, 242, 0.996078431372549);
        padding: 24px;
        color: #ffdc33;
    }
    .completeIco {
        width: 72px;
        height: 72px;
        background: #989eb6;
        color: #f8f8f8;
        border-radius: 100%;
        padding: 0px;
    }
}

.overall-center {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
}

.createAddress,
.addNote {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    left: 0px;
    top: 0px;
    bottom: 0px;
    right: 0px;
    background: inherit;
    background-color: rgba(111, 113, 145, 0.176470588235294);
    border: none;
    border-radius: 0px;
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    z-index: 10;
    .mainContainer {
        box-sizing: border-box;
        width: 960px;
        height: auto;
        background: inherit;
        background-color: rgba(255, 255, 255, 1);
        border: none;
        border-radius: 70px;
        -moz-box-shadow: none;
        -webkit-box-shadow: none;
        box-shadow: none;
        font-family: "Krub";
        font-weight: 400;
        font-style: normal;
        font-size: 40px;
        padding-bottom: 44px;
        .allContainer {
            margin: -55px 70px 0;
            height: 58.5vh;
            overflow-x: hidden;
            overflow-y: scroll;
            .address-input {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                margin-bottom: 48px;
                .text {
                    color: #9791bf;
                }
                .not-address {
                    .SearchBoxinput {
                        color: red !important;
                    }
                }
                .tipsText {
                    text-align: left;
                    padding: 0 30px;
                    margin-top: 30px;
                    color: #fd7b88;
                    font-weight: bold;
                    font-size: 40px;
                }
                .complete-google-map {
                    // height: 140px;
                    width: 98%;
                    border-radius: 88px;
                    margin: 48px 0 0 0;
                    border: 1px solid #989eb6;
                    .SearchBox {
                        width: 99%;
                    }
                    .SearchBoxinput {
                        box-sizing: border-box;
                        border: 1px solid transparent;
                        height: 130px;
                        background-color: #fff;
                        border: none;
                        border-radius: 88px;
                        padding: 0 60px;
                        outline: none;
                        font-size: 40px;
                        color: #5867b4;
                        width: 99%;
                    }
                }
                .text-second {
                    color: #8c949f;
                }
                .complete-google-map-second {
                    // height: 140px;
                    width: 98%;
                    border-radius: 88px;
                    margin: 48px 0 0 0;
                    border: none;
                    .SearchBox {
                        width: 99%;
                    }
                    .SearchBoxinput {
                        box-sizing: border-box;
                        border: 1px solid transparent;
                        height: 130px;
                        background-color: #3e3c4c;
                        border: none;
                        border-radius: 88px;
                        padding: 0 60px;
                        outline: none;
                        font-size: 40px;
                        color: #8c949f;
                        width: 99%;
                    }
                }
            }
        }
        .selectDefault {
            display: flex;
            align-items: center;
            margin: 50px 0;
            .optionIco {
                width: 48px;
                height: 48px;
                background: inherit;
                background: rgba(255, 255, 255, 1);
                box-sizing: border-box;
                border: 3px solid rgba(152, 158, 182, 1);
                border-radius: 15px;
            }
            .defaultTip {
                font-family: "Krub";
                font-weight: 400;
                font-style: normal;
                font-size: 40px;
                color: #6c6c6c;
                margin-left: 24px;
            }
            .accountInfoInvalid {
                color: red;
            }
        }
        .margin70 {
            margin: 50px 70px;
        }
    }
    .isNoComplete {
        input::-webkit-input-placeholder {
            color: red !important;
        }
        textarea::-webkit-input-placeholder {
            color: red !important;
        }
    }
    .addressInput .city::-webkit-input-placeholder {
        color: #9791bf !important;
    }
    .city {
        position: relative;

        p {
            text-align: left;
            font-size: 40px;
            font-weight: bold;
            color: #797979;
            margin-bottom: 60px;
        }
        .MuiNativeSelect-root {
            height: 78px;
            width: 400px;
            border-radius: 100px;
        }
        .MuiNativeSelect-icon {
            display: none;
        }
        .cityArrow {
            position: absolute;
            right: 30px;
            top: 135px;
            font-size: 100px;
            padding: 0;
        }
    }
    .addAddressBtn {
        width: 816px;
        height: 144px;
        background: inherit;
        background-color: rgba(255, 220, 51, 1);
        border: none;
        border-radius: 88px;
        -moz-box-shadow: 0px 10px 20px rgba(134, 113, 63, 0.109803921568627);
        -webkit-box-shadow: 0px 10px 20px rgba(134, 113, 63, 0.109803921568627);
        box-shadow: 0px 10px 20px rgba(134, 113, 63, 0.109803921568627);
        font-size: 50px;
        font-family: "Krub Medium", "Krub";
        font-weight: 500;
    }
    .not-active {
        background-color: #bcbcbc;
    }
}

.addressAndNoteHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 48px 48px 96px;
    p {
        font-size: 50px;
        font-weight: bold;
        margin-left: 20px;
    }
    .closeBtn {
        width: 72px;
        height: 72px;
        padding: 0;
    }
    .closeIco {
        font-size: 72px;
        font-weight: bold;
        color: #797979;
    }
}

.address {
    display: flex;
    justify-content: space-between;
    .homeInput {
        width: 360px;
        height: 13.889vw;
    }
    .zipInput {
        width: 401px;
        height: 13.889vw;
    }
}

.DetailsInput {
    height: 300px !important;
    margin-bottom: 20px;
}

.addNote .addAddressBtn {
    margin-top: 96px;
}

.addAddressEditBtn {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 70px 24px;
    .addAddressEdit {
        width: 100%;
        text-transform: capitalize;
        width: 380px;
        height: 144px;
        border-radius: 88px;
        font-size: 50px;
        font-weight: 500;
    }
    .ok {
        background-color: #ffdc33 !important;
        color: #000;
    }
    .cancel {
        background-color: #5867b4 !important;
        color: #fff;
    }
}

.textBox {
    display: flex;
    flex-direction: column;
    label {
        font-family: "Krub Medium", "Krub";
        font-weight: 500;
        font-style: normal;
        font-size: 40px;
        color: #9791bf;
        margin-bottom: 48px;
        text-align: left;
        margin-left: 78px;
    }
}

.addNote {
    .title {
        font-family: "Krub SemiBold", "Krub";
        font-weight: 600;
        font-style: normal;
        font-size: 55px;
        text-align: center;
        color: #000000;
    }
    .addNoteText {
        box-sizing: border-box;
        font-family: "Krub";
        font-weight: 400;
        font-style: normal;
        font-size: 40px;
        color: #9791bf;
        width: 816px;
        height: 1008px;
        background: inherit;
        background-color: rgba(248, 248, 248, 1);
        border: none;
        border-radius: 70px;
        padding: 48px 48px;
        margin: 0 auto;
        &::-webkit-input-placeholder {
            font-family: "Krub";
            font-weight: 400;
            font-style: normal;
            font-size: 40px;
            color: #9791bf;
        }
    }
}

.popverBoxTip {
    position: absolute;
    bottom: 6%;
    left: 0;
    right: 0;
    color: red;
}

.defaultAddressCars {
    box-sizing: border-box;
    box-sizing: border-box;
    position: relative;
    background: inherit;
    background-color: rgba(50, 49, 97, 1);
    border: none;
    border-radius: 40px;
    -moz-box-shadow: 0px 10px 15px rgba(157, 163, 196, 0.498039215686275);
    -webkit-box-shadow: 0px 10px 15px rgba(157, 163, 196, 0.498039215686275);
    box-shadow: 0px 10px 15px rgba(157, 163, 196, 0.498039215686275);
    padding: 56px 47px;
    margin-top: 50px;
    word-break: break-word;
    .editModifyBtn {
        position: absolute;
        right: 40px;
        top: 48px;
        width: 72px;
        height: 72px;
        color: #5867b4;
    }
    ul {
        box-sizing: border-box;
        li {
            display: flex;
            margin-top: 47px;
            &:nth-of-type(1) {
                margin-top: 0;
                p {
                    margin-top: 0;
                }
            }
            .content {
                box-sizing: border-box;
                flex: 1;
                text-align: left;
                margin-left: 48px;
                padding-right: 40px;
            }
            p {
                font-size: 40px;
                margin-top: 20px;
                font-weight: unset !important;
                line-height: 50px;
            }
            .addressBtn {
                width: 72px;
                height: 72px;
            }
            .city {
                font-size: 40px;
                color: #fff;
                padding-right: 9px;
            }
            .userName,
            .addressPhone {
                color: #bcbcbc;
            }
            .isNotWithin {
                display: inline;
                font-size: 40px;
                margin-left: 0;
                padding: 10px 20px;
                color: #fd7b88;
                background-color: #f9e9e9;
                line-height: 80px;
                border-radius: 10px;
                text-align: left;
            }
        }
    }
}

.noDeafultAddressCars {
    background: white;
    box-shadow: 0px 10px 15px rgba(242, 242, 242, 1);
    border: 1px solid rgba(188, 188, 188, 0.3);
    .editModifyBtn {
        color: #bcbcbc;
    }
    p {
        font-family: "Krub";
        font-weight: 400;
        font-style: normal;
        font-size: 40px;
        color: #6c6c6c;
    }
    .city {
        color: #6c6c6c !important;
        // padding-right:9px;
    }
}
.errorBorder {
    border: 1px solid #fd7b88;
}
.tipBox {
    border-width: 0px;
    position: absolute;
    left: 200px;
    top: 77px;
    width: 704px;
    height: 120px;
    background: inherit;
    background-color: rgba(33, 33, 57, 1);
    border: none;
    border-radius: 30px;
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    line-height: 120px;
    &::after {
        position: absolute;
        bottom: -21px;
        right: calc(50% - 12px);
        content: "";
        border-left: 23px solid transparent;
        border-right: 23px solid transparent;
        border-top: 23px solid rgba(33, 33, 57, 1);
    }
    span {
        font-family: "Krub";
        font-weight: 400;
        font-style: normal;
        font-size: 40px;
        color: #ffffff;
        text-align: center;
    }
}

.paymentBox {
    border-width: 0px;
    position: fixed;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    background: inherit;
    background-color: #00000033;
    border: none;
    border-radius: 0px;
    box-shadow: none;

    .defaultMainPaymentBox {
        box-sizing: border-box;
        position: fixed;
        left: 0px;
        bottom: 0px;
        width: 100%;
        min-height: 840px;
        max-height: 100%;
        overflow-y: scroll;
        background-color: rgba(255, 255, 255, 1);
        border-radius: 70px;
        border-bottom-right-radius: 0px;
        border-bottom-left-radius: 0px;
        padding: 0 72px 72px;
        .arrowBottomIco {
            position: absolute;
            left: calc(50% - 40px);
            font-size: 80px;
            color: #bcbcbc;
        }
        .orderMoreBox {
            margin-bottom: 48px;
            margin-top: 96px;
            font-family: "Krub", "Krub";
            font-style: normal;
            font-size: 40px;
            color: #5867b4;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            .orderMore {
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }
        .viewCouponsBox {
            margin: 48px 0;
            display: flex;
            align-items: center;
            justify-content: space-between;
            .viewCoupons {
                font-family: "Krub", "Krub";
                font-style: normal;
                font-size: 40px;
                color: #5867b4;
            }
        }
        .paymentMethod {
            margin: 48px 0;
        }
        .coupontitle {
            font-family: "Krub Bold", "Krub";
            font-weight: 700;
            font-style: normal;
            font-size: 40px;
            color: #6c6c6c;
        }
        .paymentCouponPackage {
            width: 106%;
            display: flex;
            align-items: flex-start;
            overflow-x: scroll;
            overflow-y: hidden;
            // margin: 0 24px;
            .couponItemBox {
                margin: 0 24px;
                &:nth-of-type(1) {
                    margin-left: 0;
                }
            }
            .ruleText {
                width: 80%;
            }
            .noCouponsText {
                color: #989eb6;
                font-size: 30px;
            }
        }
        .paymentMethod {
            text-align: left;
            position: relative;
        }
        .paypalButton {
            position: relative;
            display: flex;
            overflow-x: scroll;
            overflow-y: hidden;
            width: 106%;
            .workBubble {
                position: absolute;
                top: -80px;
                left: 20px;
                padding: 20px 30px;
                min-width: 300px;
                background: inherit;
                background-color: #323161;
                border: none;
                border-radius: 100px;
                &::after {
                    position: absolute;
                    bottom: -23px;
                    right: calc(50% - 20px);
                    content: "";
                    border-left: 24px solid transparent;
                    border-right: 24px solid transparent;
                    border-top: 24px solid #323161;
                }
                span {
                    color: #ffffff;
                    font-size: 30px;
                    line-height: 48px;
                    font-weight: 400 !important;
                }
            }
            .last {
                .paymentWayBtn {
                    margin-right: 0;
                    flex-direction: column;
                }
            }
            .paymentWayBtn {
                width: 350px;
                height: 125px;
                color: #5867b4;
                border: 1px solid #5867b4;
                border-radius: 30px;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-right: 12px;
                background-size: 100%;
                background-repeat: no-repeat;
                background-position: center;
                font-size: 30px;
                span {
                    display: block;
                    color: #9791bf;
                    font-size: 30px;
                }
                svg {
                    margin-right: 10px;
                }
            }
            .paypalBtn {
                background-image: url("../../static/payment-images/PayPal_logo.png");
                background-size: 85%;
            }
            .stripeBtn {
                background-image: url("../../static/payment-images/stripe_logo.png");
            }
            .payNowBtn {
                background-image: url("../../static/payment-images/2paynow_logo.png");
            }
            .noPaymentMethodSet {
                color: #5867b4;
                font-size: 30px;
                font-family: "kurb Regular";
            }
        }
        .twoPayNowWays {
            display: flex;
            overflow-x: scroll;
            overflow-y: hidden;
            width: 106%;
            margin-top: 72px;
            div {
                flex: 1;
                background-size: 100%;
                background-repeat: no-repeat;
                background-position: center;
                height: 125px;
                color: #5867b4;
                border: 1px solid #5867b4;
                border-radius: 30px;
                margin: 0 6px;
            }
            div:nth-last-of-type(1) {
                margin-right: 0;
            }
            div:nth-of-type(1) {
                margin-left: 0;
            }
            // .aliPay {
            //     background-image: url("../../static/payment-images/zhifubao_logo.png");
            // }
            // .weChat {
            //     background-image: url("../../static/payment-images/weixin_logo.png");
            // }
            // .bankCard {
            //     background-image: url("../../static/payment-images/yinhangka_logo.png");
            //     display: flex;
            //     align-items: flex-end;
            //     flex-direction: column;
            //     justify-content: center;
            //     color: #333;
            //     span {
            //         width: 60%;
            //         font-size: 30px;
            //     }
            // }
        }
        .couponbox {
            display: flex;
            align-items: center;
            padding: 48px 0;
            border-bottom: 2px solid #989eb6;

            .doneicon {
                width: 48px;
                height: 48px;
                border-radius: 48px;
                font-weight: bold;
                color: #ffdc33;
                background: #3d3c69;
            }
            .undoicon {
                color: #fff;
                background: #fff;
                border: 1px solid #3d3c69;
            }
            .couponRow {
                border-bottom: 0px solid;
                padding: 5px 0;
            }
            .generalRow {
                display: flex;
                align-items: center;
                .placeholderBox {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 48px;
                    height: 48px;
                    border-radius: 48px;
                }
                p {
                    font-size: 40px;
                    font-weight: bold;
                    font-family: "Krub";
                    font-weight: 400;
                    font-style: normal;
                    color: #858585;
                    margin-left: 24px;
                }
                .atLeast {
                    font-size: 30px;
                    font-family: "Krub";
                    font-weight: 400;
                    font-style: normal;
                    color: #858585;
                    padding-left: 10px;
                }
            }
        }
        .couponText {
            color: #5867b4 !important;
            font-size: 55px;
            font-family: "kurb Regular";
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin: 24px 0;
            .title {
                font-family: "Krub Bold", "Krub";
                font-weight: 700;
                font-style: normal;
                font-size: 40px;
                color: #bcc0cf;
            }
            .delLine {
                text-decoration: line-through; //删除线
            }
            .bold {
                font-weight: bold;
            }
            .fontSize48 {
                font-size: 48px;
            }
            .couponIcon {
                width: 48px;
                height: 48px;
                color: #5867b4 !important;
                margin: 0 24px;
                display: flex;
                align-content: center;
            }
            .itemCenter {
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 48px;
            }
        }
        .totalPrice {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin: 48px 0 33px;

            .title {
                font-family: "Krub Bold", "Krub";
                font-weight: 700;
                font-style: normal;
                font-size: 40px;
                color: #6c6c6c;
            }
            .price {
                font-family: "Krub Bold", "Krub";
                font-weight: 700;
                font-style: normal;
                font-size: 55px;
                color: #000000;
            }
        }
        .shippingFee {
            display: flex;
            justify-content: flex-end;
            span {
                font-family: "Krub Medium", "Krub";
                font-weight: 500;
                font-style: normal;
                font-size: 40px;
                text-align: right;
                color: #bcc0cf;
                &:nth-of-type(2) {
                    color: #000;
                    padding-left: 10px;
                }
            }
        }
        .footerBtn {
            display: flex;
            align-items: center;
            margin-top: 48px;
            color: #333;
            .checkoutBtn {
                flex: 1;
                font-weight: 500;
                font-style: normal;
                text-align: center;
                height: 144px;
                background: inherit;
                background-color: rgba(255, 220, 51, 1);
                border-radius: 88px;
                -moz-box-shadow: 0px 10px 20px rgba(134, 113, 63, 0.109803921568627);
                -webkit-box-shadow: 0px 10px 20px rgba(134, 113, 63, 0.109803921568627);
                box-shadow: 0px 10px 20px rgba(134, 113, 63, 0.109803921568627);
                font-size: 50px;
                border: none;
            }
            .mdEditIco {
                box-sizing: border-box;
                width: 184px;
                height: 184px;
                box-shadow: 0px 0px 1px 6px rgba(134, 113, 63, 0.11);
                margin-left: 48px;
                border-radius: 184px;
                padding: 35px;
            }
            .isAddNoted {
                background: #5b6ab6;
                color: #fff;
                box-shadow: none;
            }
        }
        .forbidPaymentBtn {
            background: #bcbcbc !important;
        }
    }
}

.paymentComplete {
    position: fixed;
    left: 0px;
    top: 0px;
    right: 0;
    bottom: 0;
    background: inherit;
    background-color: rgba(111, 113, 145, 0.176470588235294);
    border: none;
    border-radius: 0px;
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    display: flex;
    align-items: center;
    justify-content: center;
    .paymentMainBox {
        position: absolute;
        // left: calc(50vw - 480px);
        // top: calc(50vh - 384px);
        width: 960px;
        height: 772px;
        background: inherit;
        background-color: rgba(255, 252, 252, 1);
        border: none;
        border-radius: 70px;
        -moz-box-shadow: none;
        -webkit-box-shadow: none;
        box-shadow: none;
        font-family: "Krub";
        font-weight: 400;
        font-style: normal;
        font-size: 40px;
        .title {
            font-family: "Krub SemiBold", "Krub";
            font-weight: 600;
            font-style: normal;
            font-size: 55px;
            text-align: center;
            margin: 48px 0 118px;
        }
        .content {
            font-family: "Krub Medium", "Krub";
            font-weight: 500;
            font-style: normal;
            font-size: 50px;
            color: #323161;
            text-align: center;
            // margin-top: 22px;
        }
        .track {
            font-family: "Krub Medium", "Krub";
            font-weight: 500;
            color: #989eb6;
            font-style: normal;
            font-size: 40px;
            text-align: center;
            margin-top: 40px;
            span {
                font-family: "Krub Bold", "Krub";
                font-weight: 700;
                color: #394474;
                font-style: normal;
                font-size: 50px;
                text-align: center;
            }
        }
        .orderConfirmation {
            margin-top: 20px;
        }
        .returnBtn {
            width: 816px;
            height: 144px;
            background: inherit;
            background-color: rgba(255, 220, 51, 1);
            border: none;
            border-radius: 88px;
            -moz-box-shadow: 0px 10px 20px rgba(134, 113, 63, 0.109803921568627);
            -webkit-box-shadow: 0px 10px 20px rgba(134, 113, 63, 0.109803921568627);
            box-shadow: 0px 10px 20px rgba(134, 113, 63, 0.109803921568627);
            font-size: 50px;
            margin-top: 48px;
        }
    }
}
.blackGoldPaymentComplete {
    background-color: #00000080;
    .paymentMainBox {
        .title {
            color: #caa376;
            margin: 0 0 48px 0;
            padding: 48px 0;
            background-color: #36353c;
            border-top-left-radius: 30px;
            border-top-right-radius: 30px;
        }
        background-color: #282733;
        .content {
            color: #fff;
        }
        .track {
            color: #fff;
            span {
                color: #caa376;
                text-decoration: underline;
            }
        }
        .returnBtn {
            background-color: #b07c4d;
            border-radius: 30px;
            width: 350px;
            color: #fff;
        }
    }
}

.thirdPartyBox {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    bottom: 0;
    top: 0;
    right: 0;
    left: 0;
    background: rgba(111, 113, 145, 0.176470588235294);
    .mainContainer {
        width: 952px;
        height: 1320px;
        background: inherit;
        background-color: rgba(255, 255, 255, 1);
        border: none;
        border-radius: 70px;
        -moz-box-shadow: 0px 30px 80px rgba(182, 177, 191, 0.894117647058824);
        -webkit-box-shadow: 0px 30px 80px rgba(182, 177, 191, 0.894117647058824);
        box-shadow: 0px 30px 80px rgba(182, 177, 191, 0.894117647058824);
        font-family: "Krub";
        font-weight: 400;
        font-style: normal;
        .mdClearIco {
            width: 72px;
            height: 72px;
            float: right;
            margin: 39px 39px 0 0;
        }
        .paypalLogo {
            width: 230px;
            height: 230px;
            margin: 42px 358px 0;
        }
        .title {
            font-family: "Krub SemiBold", "Krub";
            font-weight: 600;
            font-style: normal;
            font-size: 55px;
            color: #000000;
            text-align: center;
            margin-top: 93px;
        }
        .way {
            font-size: 40px;
            color: #bcbcbc;
            text-align: center;
            margin: 18px 0 23px;
        }
        .tag {
            display: flex;
            justify-content: center;
            align-items: center;
            font-family: "Krub Medium", "Krub";
            font-weight: 500;
            font-style: normal;
            font-size: 40px;
            color: #ffffff;
            text-align: center;
            span {
                display: inline-block;
                background: #6aaaff;
                padding: 10px 40px;
                border-radius: 100px;
                &:nth-of-type(2) {
                    margin-left: 30px;
                }
            }
        }
        .tipContent {
            font-size: 40px;
            color: #bcbcbc;
            text-align: left;
            margin: 65px 113px 0 103px;
        }
    }
}

.selecedMathod {
    // background: #5b6ab6 !important;
    border: 5px solid #ffdc33 !important;
}

.disabledPaymenMethodBtn {
    opacity: 0.3;
}

.payMentLoading,
.PaymentError {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    bottom: 0;
    top: 0;
    right: 0;
    left: 0;
    background: rgba(111, 113, 145, 0.176470588235294);
    .mainContainer {
        display: flex;
        flex-direction: column;
        // justify-content: center;
        align-items: center;
        width: 952px;
        height: 1320px;
        background: inherit;
        background-color: rgba(255, 255, 255, 1);
        border: none;
        border-radius: 70px;
        -moz-box-shadow: 0px 30px 80px rgba(182, 177, 191, 0.894117647058824);
        -webkit-box-shadow: 0px 30px 80px rgba(182, 177, 191, 0.894117647058824);
        box-shadow: 0px 30px 80px rgba(182, 177, 191, 0.894117647058824);
        font-family: "Krub";
        font-weight: 400;
        font-style: normal;
        p {
            margin-top: 430px;
            font-family: "Krub SemiBold", "Krub Regular", "Krub";
            font-weight: 600;
            font-style: normal;
            font-size: 46px;
            color: #989eb6;
        }
        .loading {
            position: relative;
            background: none;
            margin-top: 100px;
            color: #ffdc33;
        }
        .loadingBtn {
            width: 745px;
            height: 144px;
            background: inherit;
            // background-color: #5968B4;
            background: rgba(89, 104, 180, 0.9);
            border: none;
            border-radius: 88px;
            -moz-box-shadow: 0px 10px 20px rgba(134, 113, 63, 0.109803921568627);
            -webkit-box-shadow: 0px 10px 20px rgba(134, 113, 63, 0.109803921568627);
            box-shadow: 0px 10px 20px rgba(134, 113, 63, 0.109803921568627);
            font-size: 50px;
            font-weight: bold;
            color: white;
            margin-top: 100px;
        }
    }
}

.blackGOldpayMentLoading {
    background-color: #00000080;
    .mainContainer {
        box-shadow: unset;
        background-color: #282733;
        p {
            color: #b4b5b5;
        }
        .loadingBtn {
            background-color: #b07c4d;
            border-radius: 30px;
            color: #fff;
        }
    }
}

.PaymentError {
    .mainContainer {
        position: relative;
        .closeIco {
            position: absolute;
            width: 72px;
            height: 72px;
            right: 48px;
            top: 48px;
        }
        .errorIco {
            width: 100px;
            height: 100px;
            color: #fc432d;
            margin-top: 430px;
        }
        p {
            margin-top: 60px;
        }
        a {
            font-size: 60px;
            color: #3d3c69;
            margin-top: 50px;
        }
    }
    .returnBtn {
        width: 816px;
        height: 144px;
        background: inherit;
        background-color: rgba(255, 220, 51, 1);
        border: none;
        border-radius: 88px;
        -moz-box-shadow: 0px 10px 20px rgba(134, 113, 63, 0.109803921568627);
        -webkit-box-shadow: 0px 10px 20px rgba(134, 113, 63, 0.109803921568627);
        box-shadow: 0px 10px 20px rgba(134, 113, 63, 0.109803921568627);
        font-size: 50px;
        margin-top: 48px;
    }
}

.blackGoldPaymentError {
    background-color: #00000080;
    .mainContainer {
        box-shadow: unset;
        background-color: #282733;
        p {
            color: #b4b5b5;
        }
        .loadingBtn {
            background-color: #b07c4d;
            border-radius: 30px;
            color: #fff;
        }
        a {
            color: #b07c4d;
        }
    }
}

.shippingFee {
    height: 50px;
}

.phoneInput {
    .changePhone {
        display: flex;
        padding: 0 24px;
        height: 96px;
        border-radius: 88px;
        background-color: #ffdc33;
        justify-items: center;
        align-items: center;
        font-size: 40px;
        white-space: nowrap;
        color: #000;
        margin-right: 24px;
        font-family: "kurb";
    }
    .title {
        text-align: left;
        font-family: "Krub Medium", "Krub";
        font-weight: 500;
        font-style: normal;
        font-size: 40px;
        color: #9791bf;
        margin-bottom: 20px;
    }

    .mainContainer {
        display: flex;
        align-items: center;
        box-sizing: border-box;
        display: flex;
        width: 100%;
        height: 144px;
        border: 3px solid rgba(152, 158, 182, 1);
        border-radius: 300px;
        font-weight: 500;
        font-style: normal;
        font-size: 40px;
        color: #bcbcbc;
        text-align: center;
        padding: 0 0 0 50px;
        margin: 72px 0 32px;
        .title {
            font-family: "Krub Medium", "Krub";
            font-weight: 500;
            font-style: normal;
            font-size: 40px;
            color: #9791bf;
        }
        .phone {
            width: 100%;
            border: none;
            font-weight: 400;
            font-style: normal;
            font-size: 40px;
            color: #333;
            border: none;
            // border-radius: 70px;
            margin: 0 auto;
            &::-webkit-input-placeholder {
                font-family: "Krub";
                font-weight: 400;
                font-style: normal;
                font-size: 40px;
                color: #9791bf;
            }
        }
        .country-select-menu {
            height: 500px !important;
            // overflow-y: hidden;
            .css-2b097c-container {
                height: 100%;
                overflow: hidden;
            }
            .css-106o0pu-Menu {
                // height: 100%;
                overflow-y: scroll;
            }
        }
    }
}
