.PrivacyApgreement {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    background-color: #f8f8f8;
    z-index: 1;
    .PrivacyApgreementText {
        font-family: "Bookman Old Style";
        overflow-y: scroll;
        height: calc(100% - 196px - 96px);
        padding: 48px;
        background-color: #fff;
        text-align: left;
    }
}
