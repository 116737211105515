.BlackGoldOrderbuttons {
    height: 250px;
    display: flex;
    background-color: transparent;
    justify-content: space-around;
    align-items: center;
    position: absolute;
    bottom: 0;
    width: 100%;
    padding: 0 24px;
    box-sizing: border-box;
    font-family: "krub";
    z-index: 1;
    div {
        border-radius: 40px;
        background-color: #b07c4d;
        color: #fff;
        width: 40%;
        height: 130px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .title {
        font-size: 40px;
        padding-left: 10px;
        font-weight: normal;
        // font-family: Regular;
    }
    .disable {
        color: #fff !important;
        opacity: 0.5;
        background: #323161;
    }
}
