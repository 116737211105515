.scanCode{
    position: fixed;
    bottom: 0;
    top: 0;
    left: 0;
    right: 0;
    background: #fff;
    z-index: 1;
}
.tipTitle{
    font-family: "Krub SemiBold", "Krub Regular", Krub;
    font-weight: 600;
    font-style: normal;
    font-size: 40px;
    color: rgb(152, 158, 182);
    margin: 50px;
}
.selectBtn{
    display: block;
    width: 745px;
    height: 144px;
    background: inherit;
    background: rgba(89, 104, 180, .9);
    border: none;
    border-radius: 88px;
    -moz-box-shadow: 0px 10px 20px rgba(134, 113, 63, 0.109803921568627);
    -webkit-box-shadow: 0px 10px 20px rgba(134, 113, 63, 0.109803921568627);
    box-shadow: 0px 10px 20px rgba(134, 113, 63, 0.109803921568627);
    font-size: 50px;
    font-weight: bold;
    color: #fff;
    margin: 100px auto 0;
    line-height: 144px;
}

.result, .errorTip{
    font-family: "Krub SemiBold", "Krub Regular", Krub;
    font-weight: 600;
    font-style: normal;
    font-size: 40px;
    color: rgb(152, 158, 182);
    margin: 50px;  
}
.errorTip{
    color: red;
}
.clearIco{
    position: absolute;
    right: 48px;
    top: 48px;
    width: 62px;
    height: 62px;
    background: rgba(0,0,0,.3);
    border-radius: 100%;
    padding: 8px;
    color: #666;
    z-index: 999;
}