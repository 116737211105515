.shareBoxBackgorund {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1000;
    background: #00000080;
    .shareContent {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        height: 1096px;
        background-color: #212139;
        border-top-left-radius: 58px;
        border-top-right-radius: 58px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-direction: column;
        .QRpicbox {
            margin-top: 72px;
        }
        .QRpic {
            width: 528px;
            height: 528px;
            border-radius: 50px;
        }
        .shareItemPic {
            width: 120px;
            height: 120px;
            border-radius: 20px;
            margin: 0 32px;
            svg {
                width: 120px;
                height: 120px;
                border-radius: 20px;
            }
        }
        .shareText {
            color: #fff;
            line-height: 40px;
            font-size: 48px;
            margin: 48px 0;
        }
        .closeBox {
            margin: 72px 0;
        }
        .closeButton {
            width: 96px;
            height: 96px;
            border-radius: 48px;
            background-color: #33344d;
            color: #fff;
            display: flex;
            align-items: center;
            justify-content: center;
            .colseicon {
                width: 50%;
                height: 50%;
            }
        }
    }
}
