/* reset */
html,body,h1,h2,h3,h4,h5,h6,div,dl,dt,dd,ul,ol,li,p,pre,hr,figure,table,caption,th,td,form,fieldset,legend,input,button,textarea,menu{margin:0;padding:0;}
header,footer,section,article,aside,nav,hgroup,address,figure,figcaption,menu,details{display:block;}

/* $ 解决手机浏览器默认上下滑动白色区域 （请不要更改） */
html,body{
    position:fixed;width:100%;height:100%;
    /* $ 禁止浏览器下拉刷新 */
    overflow:hidden;
}
table{border-collapse:collapse;border-spacing:0;}
caption,th{text-align:left;font-weight:normal;}
html,body,fieldset,img,iframe,abbr{border:0;}
i,cite,em,let,address,dfn{font-style:normal;}
[hidefocus],summary{outline:0;}
li{list-style:none;}
/* h1,h2,h3,h4,h5,h6,small{font-size:100%;} */
sup,sub{font-size:83%;}
pre,code,kbd,samp{font-family:inherit;}
q:before,q:after{content:none;}
textarea{overflow:auto;resize:none;}
label,summary{cursor:default;}
a,button{cursor:pointer;}
h1,h2,h3,h4,h5,h6,em,strong,b{font-weight:bold;}
del,ins,u,s,a,a:hover{text-decoration:none;}
body,textarea,input,button,select,keygen,legend{font:12px/1.14 arial,\5b8b\4f53;color:#333;outline:0;}
body{background:#fff;}
a,a:hover{color:#333;}

/* 公共数据 */
/* 没有数据的时候 */
.no_related_date{
    font-size: 50px;
    margin-top: 600px;
    color: #BCBCBC;
}
.ignore_body{
    max-width: 1080px;
}

.socketTip{
    position: absolute;
    top: 0;
    width: 90%;
    left: 5%;
}

/* $  滚动条样式 */
::-webkit-scrollbar {
    box-sizing: border-box;
    width: 12px;
    height: 12px;
    background: #fff;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: #eee;
  }
  ::-webkit-scrollbar-corner {
    background: #fff;
  }
  ::-webkit-scrollbar-track {
    background: #fff;
    border-radius: 10px;
  }
  ::-webkit-scrollbar-track-piece {
    background: #fff;
    width: 8px;
  }


