.ReCaptChaDialog {
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    .MuiDialog-paper {
        border-radius: 42px;
        margin: 0;
    }
    .MuiDialogContent-root:first-child {
        padding: 48px 48px;
    }
    .dialogContent {
        display: flex;
        flex-direction: column;
    }
    .dialogName {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 72px;
        .close {
            position: absolute;
            top: 0;
            right: 0;
        }
        .fontStyle {
            font-size: 55px;
            width: 100%;
        }
    }
}
