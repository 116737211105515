.PrivacyApgreementDialog {
    position: absolute !important;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    // margin: auto;
    background-color: #fff;
    .PrivacyApgreementText {
        font-family: "Bookman Old Style";
        // overflow-y: scroll;
        // padding-bottom: 268px;
    }
    .close {
        position: absolute;
        top: 0;
        right: 0;
        width: 120px;
        height: 120px;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 1;
    }
    .PrivacyApgreementLogoBox {
        display: flex;
        align-items: center;
        justify-content: center;
        .PrivacyApgreementLogo {
            width: 224px;
            height: 224px;
        }
    }
    .subTitle {
        display: flex;
        align-items: center;
        justify-content: center;
        color: #ffdc33;
        margin: 24px 0 48px;
        span {
            // width: 33%;
            text-align: center;
            font-size: 40px;
            overflow: hidden;
        }
    }
    .MuiDialogContent-root {
        margin: 48px 0;
        padding: 0 72px !important;
    }
    .dialogContent {
        display: flex;
        flex-direction: column;
        background-color: #ffdc33 !important;
        color: #000;
        border-radius: 88px;
        padding: 48px;
        margin: 48px auto 128px;
    }
    .agreeBox {
        position: fixed;
        bottom: 0;
        width: calc(972px - 166px);
    }
    .MuiDialog-paperWidthSm {
        max-width: 972px;
        border-radius: 40px;
    }

    .MuiDialog-paperScrollPaper {
        max-height: calc(100% - 128px);
        position: relative;
    }
}
