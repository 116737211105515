.payment_page {
    background: #f8f8f8;
    overflow: hidden;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}
.DefaultThemePaymentPage .selectPickup {
    display: flex;
    margin: 44px 72px 0;
    .pickUpSelect,
    .deliverySelect {
        position: relative;
        display: flex;
        flex: 1;
        align-items: center;
        justify-content: center;
        height: 168px;
        background: inherit;
        background: rgba(255, 220, 51, 1);
        border: none;
        border-radius: 95px;
        -moz-box-shadow: 0px 10px 15px rgba(255, 220, 51, 0.298039215686275);
        -webkit-box-shadow: 0px 10px 15px rgba(255, 220, 51, 0.298039215686275);
        box-shadow: 0px 10px 15px rgba(255, 220, 51, 0.298039215686275);
        &:nth-of-type(2) {
            margin-left: 72px;
        }
        p {
            font-family: "Krub Bold", "Krub";
            font-weight: 700;
            font-style: normal;
            font-size: 40px;
            color: #000000;
        }
    }
    .isNoSelect {
        background-color: rgba(255, 255, 255, 1);
        -moz-box-shadow: 0px 10px 15px rgba(242, 242, 242, 0.996078431372549);
        -webkit-box-shadow: 0px 10px 15px rgba(242, 242, 242, 0.996078431372549);
        box-shadow: 0px 10px 15px rgba(242, 242, 242, 0.996078431372549);
        .mdDoneIco {
            background: #f8f8f8 !important;
        }
    }

    .mdDoneIco {
        box-sizing: border-box;
        margin-right: 48px;
        background: white !important;
        width: 120px;
        height: 120px;
        border-radius: 100%;
        font-weight: bold;
        color: rgba(255, 220, 51, 1);
        padding: 10px;
    }
}
.DefaultThemePaymentPage .payment_main_container {
    display: flex;
    justify-content: space-between;
    margin-top: 73px;
    .flow_list {
        // display: flex;
        // flex-direction: column;
        flex: 1;
        margin-right: 48px;
        height: 1400px;
        overflow-y: scroll;
        & > li:nth-last-of-type(2) {
            // padding-bottom: 316px;
        }
        .vCodeDisabledBox {
            background: #b4b5b580 !important;
            .signup-password-input {
                background: transparent !important;
            }
            .sign-up-button {
                background-color: #b4b5b5;
            }
        }
        .vCodeMismatched {
            text-align: left;
            color: #f00;
            font-size: 30px;
            padding: 23px;
        }
        .workTimeTip {
            font-family: "Krub Medium", "Krub Regular", "Krub";
            font-weight: 500;
            font-style: normal;
            font-size: 40px;
            color: #bcbcbc;
            text-align: left;
            margin: 48px 0;
            span {
                font-weight: 600;
                color: #989eb6;
                margin-left: 30px;
            }
        }
        .deliveryOrPickUp {
            height: 108px;
        }
        .deliveryTime,
        .deliveryOrPickUp {
            position: relative;
            display: flex;
            align-items: center;
            justify-content: space-between;
            p {
                font-family: "Krub Bold", "Krub";
                font-weight: 700;
                font-style: normal;
                font-size: 40px;
            }
            .workBubble {
                position: absolute;
                top: -80px;
                right: 180px;
                padding: 24px;
                background: inherit;
                background-color: rgba(33, 33, 57, 1);
                border: none;
                border-radius: 30px;
                &::after {
                    position: absolute;
                    bottom: -23px;
                    right: calc(50% - 20px);
                    content: "";
                    border-left: 24px solid transparent;
                    border-right: 24px solid transparent;
                    border-top: 24px solid rgba(33, 33, 57, 1);
                }
                span {
                    color: #ffffff;
                    font-size: 30px;
                    line-height: 48px;
                    font-weight: 400 !important;
                }
            }
        }
        .deliveryTime {
            height: 10vw;
        }
        .busyHoursText {
            display: flex;
            span {
                font-size: 35px;
                display: inline;
                padding: 10px 20px;
                color: #d46b6b;
                background-color: #d46b6b23;
                line-height: 80px;
                border-radius: 10px;
                text-align: left;
            }
        }
        .selectDateContainer {
            display: flex;
            align-items: center;
            .date_selected {
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 5px 30px;
                border-radius: 80px;
                background-color: white;
                box-shadow: 0px 10px 15px rgba(242, 242, 242, 0.996078431372549);
                margin: 40px 0;
                &:nth-of-type(2) {
                    margin-left: 20px;
                }
                span {
                    font-family: "Krub";
                    font-weight: 400;
                    font-style: normal;
                    font-size: 40px;
                    color: #9791bf;
                }
                .arrow_btn {
                    width: 80px;
                    height: 80px;
                    color: #9791bf;
                }
            }
        }

        .deliveryOrPickUp {
            .addIco {
                width: 61px;
                height: 61px;
            }
        }

        .customerNameBox {
            flex: 1;
            display: flex;
            .customerNameInput {
                margin: 0;
                font-family: "krub";
            }
        }
        .errorInput {
            input::-webkit-input-placeholder {
                color: red !important;
            }
        }
        .customerInfoPhoneInput {
            .mainContainer {
                background-color: #fff;
                margin-top: 0;
            }
            .disabledMainContainer {
                background-color: #b4b5b580;
                .phone {
                    background-color: transparent;
                }
            }
        }
        .paymentVerification {
            display: flex;
            flex-direction: row;
            background-color: #fff;
            border-radius: 88px;
            align-items: center;
            justify-content: space-between;
            height: 144px;
            margin-top: 48px;
            border: 1px solid #989eb6;
            font-size: 30px;
            padding: 0 24px;
            button:hover {
                background-color: unset;
            }
        }
        .customerNameReadOnly {
            background-color: #b4b5b580;
        }
    }
}

.addressInput {
    display: flex;
    flex-direction: column;
    flex: 1;
    label {
        text-align: left;
        font-family: "Krub Medium", "Krub";
        font-weight: 500;
        font-style: normal;
        font-size: 40px;
        color: #9791bf;
    }
    input {
        box-sizing: border-box;
        font-size: 40px;
        height: 144px;
        border-radius: 144px;
        outline-color: invert;
        outline-style: none;
        outline-width: 0px;
        border: 1px solid rgba(152, 158, 182, 1);
        text-shadow: none;
        -webkit-appearance: none;
        -webkit-user-select: text;
        outline-color: transparent;
        box-shadow: none;
        padding: 0 48px;
        color: #9791bf !important;
        margin: 48px 0 71px;
        width: 100%;
        &::-webkit-input-placeholder {
            font-size: 40px;
            color: #9791bf !important;
        }
    }
}

.datepicker.default .datepicker-header {
    color: #9791bf !important;
}
.datepicker.default .datepicker-navbar-btn {
    color: #9791bf !important;
}
.datepicker.default .datepicker-wheel {
    border-color: #9791bf !important;
}

.DefaultThemePaymentPage .addressList {
    display: flex;
    flex-direction: column;
    // flex: 38vh;
    // overflow-y: scroll;
    padding-bottom: 316px;
    // max-height: 400px;
}
.DefaultThemePaymentPage .paymentBtn {
    position: fixed;
    bottom: 72px;
    left: 144px;
    button {
        width: 792px;
        height: 144px;
        background: inherit;
        background-color: rgba(255, 220, 51, 1);
        border: none;
        border-radius: 88px;
        -moz-box-shadow: 0px 10px 20px rgba(134, 113, 63, 0.109803921568627);
        -webkit-box-shadow: 0px 10px 20px rgba(134, 113, 63, 0.109803921568627);
        box-shadow: 0px 10px 20px rgba(134, 113, 63, 0.109803921568627);
        font-size: 50px;
        font-weight: 500;
    }
}
.DefaultThemePaymentPage .forbidPaymentBtn {
    border-radius: 88px;
    button {
        background: #bcbcbc;
        border-radius: 88px;
    }
}

.DefaultThemePaymentPage .pickupAddress {
    box-sizing: border-box;
    display: flex;
    justify-content: flex-start;
    // width: 816px;
    // height: 312px;
    background: inherit;
    background-color: rgba(50, 49, 97, 1);
    border: none;
    border-radius: 40px;
    -moz-box-shadow: 0px 10px 15px rgba(157, 163, 196, 0.498039215686275);
    -webkit-box-shadow: 0px 10px 15px rgba(157, 163, 196, 0.498039215686275);
    box-shadow: 0px 10px 15px rgba(157, 163, 196, 0.498039215686275);
    margin: 48px 48px 0 0;
    padding: 48px;
    .logo {
        width: 120px;
        height: 120px;
        img {
            width: 100%;
            height: 100%;
        }
    }
    .content {
        flex: 1;
        margin-left: 48px;
    }
    .name {
        font-family: "Krub";
        font-weight: 400;
        font-style: normal;
        font-size: 40px;
        color: #989eb6;
        font-weight: unset !important;
        text-align: left;
        margin-bottom: 24px;
    }
    .address {
        display: flex;
        font-family: "Krub";
        font-weight: 400;
        font-style: normal;
        font-size: 40px;
        color: #ffffff;
        font-weight: unset !important;
        text-align: left;
        line-height: 55px;
    }
}

.scheduleDialog .dialogbody {
    height: 656px;
}
.scheduleDialog .scheduleBox {
    // display: flex;
    // align-items: flex-start;
    .date {
        font-size: 40px;
        color: #bcbcbc;
        text-align: left;
        line-height: 72px;
        word-break: break-word;
        width: 100%;
    }
    .showTip {
        font-family: "Krub SemiBold", "Krub Regular", "Krub";
        font-weight: 600;
        font-style: normal;
        font-size: 40px;
        line-height: 72px;
        text-align: left;
        margin-left: 10px;
        white-space: nowrap;
    }
}

.paymentSuccess {
    width: 100%;
    height: 100vh;
    .title {
        font-family: "Krub SemiBold", "Krub";
        font-weight: 600;
        font-style: normal;
        font-size: 55px;
        text-align: center;
        margin-top: 500px;
    }
    .backTitle {
        font-family: "Krub Medium", "Krub";
        font-weight: 500;
        font-style: normal;
        font-size: 50px;
        color: #5968b4;
        text-align: center;
        margin: 100px 0;
    }
    button {
        width: 745px;
        height: 144px;
        background: inherit;
        background-color: #5968b4;
        border: none;
        border-radius: 88px;
        -moz-box-shadow: 0px 10px 20px rgba(134, 113, 63, 0.109803921568627);
        -webkit-box-shadow: 0px 10px 20px rgba(134, 113, 63, 0.109803921568627);
        box-shadow: 0px 10px 20px rgba(134, 113, 63, 0.109803921568627);
        font-size: 50px;
        font-weight: bold;
        color: white;
    }
}

.errorDialog {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    bottom: 0;
    top: 0;
    right: 0;
    left: 0;
    background: rgba(111, 113, 145, 0.176470588235294);
    color: #333;
    .mainContainer {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 952px;
        height: 1320px;
        background: inherit;
        background-color: rgba(255, 255, 255, 1);
        border: none;
        border-radius: 70px;
        font-family: "Krub";
        font-weight: 400;
        font-style: normal;
        .closeIco {
            position: absolute;
            width: 72px;
            height: 72px;
            right: 48px;
            top: 48px;
        }
        p {
            color: #000;
            font-size: 50px;
            margin-top: 430px;
            font-family: "Krub SemiBold", "Krub Regular", "Krub";
            font-weight: 600;
            font-style: normal;
            font-size: 46px;
            color: #989eb6;
        }
        .errorIco {
            width: 100px;
            height: 100px;
            color: #fc432d;
            margin-top: 430px;
        }
        p {
            margin-top: 60px;
        }
        a {
            font-size: 60px;
            color: #3d3c69;
            margin-top: 50px;
        }
        .jumpBtn {
            width: 745px;
            height: 144px;
            background: inherit;
            // background-color: #5968B4;
            background: rgba(89, 104, 180, 0.9);
            border: none;
            border-radius: 88px;
            -moz-box-shadow: 0px 10px 20px rgba(134, 113, 63, 0.109803921568627);
            -webkit-box-shadow: 0px 10px 20px rgba(134, 113, 63, 0.109803921568627);
            box-shadow: 0px 10px 20px rgba(134, 113, 63, 0.109803921568627);
            font-size: 50px;
            font-weight: bold;
            color: white;
            margin-top: 100px;
        }
    }
}
.blackGoldErrorDialog {
    background-color: #0000004d;
    .mainContainer {
        background-color: #282733;
        .jumpBtn {
            width: 745px;
            height: 144px;
            background: inherit;
            background: #b07c4d;
            border: none;
            border-radius: 30px;
            font-size: 50px;
            font-weight: bold;
            color: white;
            margin-top: 100px;
        }
    }
    .closeIco {
        color: #b4b5b5;
    }
}
.noAddressAir {
    position: absolute;
    top: 30px;
    right: 75px;
    padding: 20px 30px;
    min-width: 300px;
    background: inherit;
    background-color: #212139;
    border: none;
    border-radius: 20px;
    &::after {
        position: absolute;
        top: -24px;
        right: 18px;
        content: "";
        border-left: 24px solid transparent;
        border-right: 24px solid transparent;
        border-bottom: 24px solid #212139;
    }
    span {
        color: #ffffff;
        font-size: 30px;
        line-height: 48px;
        font-weight: 400 !important;
    }
}
.airBox {
    position: absolute;
    top: 0px;
    left: 0;
    padding: 20px 30px;
    // min-width: 300px;
    background: inherit;
    background-color: #212139;
    border: none;
    border-radius: 20px;
    &::after {
        position: absolute;
        bottom: -20px;
        right: calc(50% - 24px);
        content: "";
        border-left: 24px solid transparent;
        border-right: 24px solid transparent;
        border-top: 24px solid #212139;
    }
    span {
        color: #ffffff;
        font-size: 30px;
        line-height: 48px;
        font-weight: 400 !important;
    }
}
.outOfBusinessHourBox {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    top: -100px;
    width: 100%;
    .outOfBusinessHour {
        position: relative;
        &::after {
            position: absolute;
            top: unset;
            bottom: -20px;
            right: calc(50% - 24px);
            content: "";
            border-left: 24px solid transparent;
            border-right: 24px solid transparent;
            border-top: 24px solid #212139;
        }
    }
}
.pickupTimeAirBubbles {
    top: -100px;
}
.notPickupTimeAirBubbles {
    top: -100px;
}
.cardsHere {
    top: -20px;
    left: 110px;
}
.vCodeBubble {
    top: -80px;
    right: 0;
    left: unset;
}
