.blackGoldScheduleDialog ::-webkit-scrollbar {
    display: none;
}
.blackGoldScheduleDialog {
    .blackGoldScheduleDialogContent {
        background: #282733;
        color: #b5b4b4;
        border-top-left-radius: 70px;
        border-top-right-radius: 70px;
        overflow-y: scroll;
    }
    .head {
        display: grid;
        align-items: center;
        justify-content: center;
        font-size: 40px;
        margin: 24px 0;
        font-weight: bold;
        color: #8c949f;
    }
    .linebox {
        display: grid;
        align-items: center;
        justify-content: center;
        margin-top: 24px;
        .line {
            width: 150px;
            height: 20px;
            background-color: #000;
            border-radius: 10px;
        }
    }
    .dialogbody {
        // height: calc(100% - 32px - 171px - 68px);
        margin: 24px 16px;
        padding: 24px;
        background-color: #333;
        border-radius: 10px;
    }
    .showTip {
        font-family: "Krub SemiBold", "Krub Regular", "Krub";
        font-style: normal;
        font-size: 40px;
        line-height: 72px;
        text-align: left;
        white-space: nowrap;
    }
    .datebox {
        display: flex;
        align-items: flex-start;
        justify-content: center;
        font-size: 40px;
        padding: 22.5px 0;
    }
    .date {
        width: calc(100% - 580px);
        text-align: left;
        font-size: 40px;
        color: #bcbcbc;
        text-align: left;
        line-height: 72px;
    }
    .todyDate {
        color: #caa376;
    }
    .time {
        width: 580px;
        font-family: Medium;
        div {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 0px 0 20px;
        }
        span {
            width: 50%;
        }
    }
}
