.groupPage {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    .loadingBox {
        flex: 1;
        background-color: "#ffdc33";
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding-bottom: 150px;
        .loadingImg {
            width: 446px;
            height: 487px;
            object-fit: cover;
            margin-bottom: 150px;
        }
        .failLoadingImg {
            width: 446px;
            height: 375px;
            object-fit: cover;
            margin-bottom: 200px;
        }
        .waitSeciton {
            font-weight: bold;
            font-size: 40px;
            color: #000000;
        }
        .loadingIcon {
            color: #bcbcbc;
            width: 30px;
            height: 30px;
            margin-bottom: 16px;
        }
        .localFail {
            font-weight: bold;
            font-size: 40px;
            margin-bottom: 152px;
        }
        .tryAgain {
            cursor: pointer;
            margin-bottom: 48px;
            display: flex;
            justify-content: center;
            align-items: center;
            color: #ffdc33;
            font-size: 40px;
            width: 688px;
            height: 144px;
            border-radius: 235px;
            background-image: linear-gradient(to right, #161727, #212139, #282944, #50487d);
            span {
                margin-left: 16px;
            }
        }
        .clickBox {
            cursor: pointer;
            width: 635px;
            height: 144px;
            display: flex;
            justify-content: center;
            align-items: center;
            color: #d47f00;
            span {
                margin-right: 16px;
                font-size: 40px;
            }
            .arrowIcon {
                width: 36px;
                height: 36px;
            }
        }
    }
    .content {
        position: fixed;
        left: 0;
        right: 0;
        top: 171px;
        bottom: 0;
        display: flex;
        flex-direction: column;
        box-sizing: border-box;
        // border-radius: 50px;
        border-top-left-radius: 50px;
        border-top-right-radius: 50px;
        overflow: hidden;
        // padding-bottom: 300px;
        transition: all 0.2s;
        background: #f8f8f8;
        display: flex;
        z-index: 9;
        .title {
            font-size: 40px;
            font-weight: bold;
            color: #333333;
            text-align: center;
            padding: 72px 40px 0px 40px;
        }
        .restaurantList {
            overflow: auto;
            width: 100%;
            height: 100%;
            box-sizing: border-box;
            padding-left: 24px;
            padding-right: 24px;
            padding-top: 24px;
            flex: 1;
            .itemCard {
                // padding: 48px;
                padding-top: 48px;
                padding-left: 48px;
                padding-right: 48px;
                padding-bottom: 24px;
                // height: 529px;
                margin-bottom: 24px;
                border-radius: 35px;
                background-color: #ffffff;
                box-shadow: 0px 0px 7px #e5e2ec;
                .restaurantInfo {
                    display: flex;
                    flex-direction: row;
                    width: 100%;
                    padding-bottom: 48px;
                    box-sizing: border-box;
                    border-bottom: 1px solid #eceef3;
                    .imageBox {
                        width: 200px;
                        height: 200px;
                        border-radius: 35px;
                        // background-color: #000;
                        margin-right: 32px;
                        img {
                            width: 100%;
                            height: 100%;
                            border-radius: 35px;
                            overflow: hidden;
                            border: transparent;
                            object-fit: cover;
                        }
                    }
                    .restaurantContent {
                        padding-top: 10px;
                        // flex: 1;
                        width: calc(100% - 232px);
                        display: flex;
                        flex-direction: column;
                        align-items: flex-start;
                        justify-content: space-between;
                        .restaurant-list-info-tit {
                            color: #333333;
                            font-size: 40px;
                            font-weight: bold;
                            font-family: "Krub";
                        }
                        .restaurant-list-info-mark {
                            height: 59px;
                            display: flex;
                            width: 100%;
                            white-space: nowrap;
                            overflow: scroll;
                            .mark-remark {
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                height: 59px;
                                background-color: #6aaaff;
                                box-sizing: border-box;
                                border-radius: 112px;
                                font-size: 35px;
                                margin-right: 30px;
                                font-family: "Krub";
                                color: #fff;
                                .val {
                                    margin: 5px 32px;
                                }
                            }
                        }
                        .restaurant-list-info-mark::-webkit-scrollbar {
                            display: none;
                        }
                        .restaurant-list-info-startsTime {
                            width: 100%;
                            .text {
                                font-family: "Arial Normal", "Arial";
                                font-weight: 400;
                                font-style: normal;
                                color: #bcbcbc;
                                font-size: 40px;
                            }
                            .startsIcon {
                                .marginRight {
                                    margin-right: 32px;
                                }
                            }
                            .endOfMeal {
                                color: #6aaaff;
                            }
                        }
                    }
                }
                .restaurantAdress {
                    width: 100%;
                    display: flex;
                    flex-direction: row;
                    .addressIcon {
                        width: 200px;
                        height: 200px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        margin-right: 32px;
                    }
                    .address {
                        text-align: left;
                        margin-top: 32px;
                        width: 527px;
                        height: 100%;
                        white-space: wrap;
                        color: #bcbcbc;
                        font-size: 40px;
                    }
                }
            }
        }
    }
    .countdown {
        position: absolute;
        top: 100px;
        right: 100px;
        background-color: #00000080;
        border-radius: 50%;
        width: 100px;
        height: 100px;
        color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 35px;
    }
}
