.restaurantSwipeableTextMobileStepper {
  width: 100%;
  flex-grow: 1;
  height: 556px;
  background-color: #ffdc33;
  .MuiMobileStepper-dotActive {
    background-color: #ffdc33;
  }
  .swipeableimg {
    height: 456px;
    padding: 10px 0;
  }
  .swipeableimg > div {
    height: 100%;
  }
  .swipeableimg > div > div div {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .MuiMobileStepper-dots {
    width: 100vw;
    justify-content: center;
    align-items: center;
    .MuiMobileStepper-dot {
      margin: 0 10px;
    }
  }
  .MuiMobileStepper-root {
    background: transparent;
  }
  .itemimg {
    width: 100%;
    height: 100%;
    border-radius: 50px;
    object-fit: cover;
  }
  .imgbox {
    overflow: hidden;
    width: 730px;
    height: 100%;
  }
}
