.AuthenticationDialog {
    background-color: #fedc33; 
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 3;
    display: flex;
    flex-direction: column;

    .not-find {
        background-color: #fff;
        margin-top: 48px;
        flex: 1;
        border-top-left-radius: 50px;
        border-top-right-radius: 50px;
        font-size: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .dialog-content {
        background-color: #fff;
        margin-top: 48px;
        flex: 1;
        border-top-left-radius: 50px;
        border-top-right-radius: 50px;

        .auth-login-content {
            margin: 72px 48px;
            display: flex;
            flex-direction: column;
            align-items: center;



            .title-box {
                display: flex;
                justify-self: center;
                font-size: 40px;
                font-weight: bold;
                margin: 48px 0;

                .icon {
                    font-size: 40px;
                    color: #fedc33;
                    margin-right: 8px;
                }
            }

            .img-box {
                width: 320px;
                height: 320px;
                border: 1px solid #cecece90;
                border-radius: 20px;

                img {
                    border-radius: 20px;
                    min-width: 100%;
                    min-height: 100%;
                    object-fit: cover;
                    width: 100%;
                    max-height: 100%;
                }
            }

            .nickname-box {
                margin: 60px 0;
            }

            .nickname-text {
                font-size: 40px;
                font-weight: bold;
            }

            .btn-box {
                display: flex;
                flex-direction: column;
                width: 100%;
                align-items: center;

                .btn {
                    width: 80%;
                    border-radius: 150px;
                    margin-top: 48px;
                    font-size: 40px;
                }

                .ok {
                    background-color: #fdec33;
                }
            }
        }
    }
}