.scroll-wrapper {
    width: 100%;
    height: 100%;
    overflow: hidden;
    .scrollLoad{
        padding: 40px;
        min-height: 80px;
        font-size: 40px;
        font-weight: bold;
        color: #BCBCBC
    }
}