.myReservationsDetail {
    .footer .footerBtn {
        display: flex;
        align-items: center;
        justify-content: center;
        .btnDetail {
            min-width: 70vw !important;
        }
    }
   
}
